<template>
<div style="min-height: 600px">
    <div class="col-lg-12 control-section">
      <div class="content-wrapper">
        <DataTable
          ref="dataTable"
          :value="gridDataSource"
          paginator
          :rows="20"
          :rowsPerPageOptions="[10, 20, 50]"
          :scrollable="true"
          dataKey="Id"
          removableSort
          selectionMode="single"
          v-model:filters="filter"
          filterDisplay="menu"
          :globalFilterFields="globalFilter"
          @rowSelect="goToProjectDashboard">
          <template #header>
            <div class="flex xl:justify-content-end align-items-center">
              <PrimeButton style="height:36px;min-width:36px"
                label="Excel"
                icon="pi pi-file-excel"
                class="mr-2"
                outlined
                severity="secondary"
                @click="exportToExcel" />

              <span
                class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filter['global'].value"
                  :placeholder="this.$t('placeholder.search')"/>
              </span>
            </div>
          </template>
          <Column v-for="(col,index) of columns"
            :key="col.field + '_' + index" :field="col.field"
            :filterField="col.field"
            :columnKey="col.field"
            :showFilterMatchModes="false"
            sortable
            :style="{minWidth: '100px'}"
            style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">
            <template #header>
              <span v-tooltip.top="col.header"
                class="headerLabel">{{ col.header }}</span>
            </template>
            <template #body="{data, field}">
              <span v-tooltip.top="data[field]">{{ data[field] }}</span>
            </template>
            <template v-if="filter" #filter="{ filterModel}">
              <MultiSelect
                class="p-column-filter"
                v-model="filterModel.value"
                :options="getColumnFilterOptions(col.field)"
                placeholder="any"
                filter
                autoFilterFocus>
                <template #option="slotProps">
                  <div class="flex align-items-center gap-2">
                    <span>{{slotProps.option}}</span>
                  </div>
                </template>
              </MultiSelect>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import QueryBuilderFunctions from "@/mixins/query-builder-functions/queryBuilderFunctions";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import PrimeButton from "primevue/button";
import MultiSelect from "primevue/multiselect";
import InputText from "primevue/inputtext";
import * as XLSX from 'xlsx';
import { FilterMatchMode } from "primevue/api";

export default {
  name: "OfferQueryTable",
  mixins:[QueryBuilderFunctions],
  data() {
    return {
      columns:[
        { field: 'Title', header: this.$t('title') },
        { field: 'Company', header: this.$t('company') },
        { field: 'DeliveryDate', header: this.$t('deliveryDate') },
        { field: 'Probability', header: this.$t('probability') },
        { field: 'Status', header: this.$t('status') },
        { field: 'SaleValue', header: this.$t('budget') },
        { field: 'OfferValue', header: this.$t('offerValue') },
        { field: 'BonusOption', header: this.$t('bonusOption') },
        { field: 'OfferNumber', header: this.$t('offerNumber') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title:{value: null, matchMode: FilterMatchMode.IN},
        Company:{value: null, matchMode: FilterMatchMode.IN},
        DeliveryDate:{value: null, matchMode: FilterMatchMode.IN},
        Probability:{value: null, matchMode: FilterMatchMode.IN},
        Status:{value: null, matchMode: FilterMatchMode.IN},
        SaleValue:{value: null, matchMode: FilterMatchMode.IN},
        OfferValue:{value: null, matchMode: FilterMatchMode.IN},
        BonusOption:{value: null, matchMode: FilterMatchMode.IN},
        OfferNumber:{value: null, matchMode: FilterMatchMode.IN},
      },
      globalFilter:['Title', 'Company', 'DeliveryDate', 'Probability', 'Status', 'SaleValue', 'OfferValue', 'BonusOption', 'OfferNumber'],
    };
  },

  components: {
    DataTable, Column, MultiSelect, InputText, PrimeButton,
  },

  computed:{
    gridDataSource(){
      return this.prepareDataSource(this.$store.getters.offers);
    },
    
    getColumnFilterOptions() {
      return (field) => {
        // Extract unique values for the given field
        const uniqueValues = new Set(
          this.gridDataSource.map(item => item[field]).filter(value => value != null) // filter out null/undefined
        );
        return Array.from(uniqueValues).map(value => ( value ));
      };
    },
  },

  methods: {
    prepareDataSource(){
      let src = [];
      let offers = this.$store.getters.offers;

      for (let offer of offers){
        let obj = {};
        obj["Title"] = offer["Title"];
        obj["Company"] = offer["Company"].Name;
        obj["DeliveryDate"] = new Date(Number(offer["DeliveryDate"])).toLocaleDateString();
        obj["Probability"] = offer["Probability"];
        obj["Status"] = this.getOfferStatus(offer["Status"]);
        obj["SaleValue"] = offer["SaleValue"];
        obj["OfferValue"] = offer["OfferValue"];
        obj["BonusOption"] = offer['BonusOption'] ? offer["BonusOption"] : '';
        obj["OfferNumber"] = offer["OfferNumber"];
        obj["ProjectId"] = offer["ProjectId"];

        src.push(obj);
      }
      return src;
    },

    getOfferStatus(offerStatus){
      switch (offerStatus){
        case '10':
          return this.$t('open');
        case '20':
          return this.$t('sold');
        case '30':
          return this.$t('rejected');
        case '40':
          return this.$t('replaced');
        case '50':
          return this.$t('lost');
        case '60':
          return this.$t('delayed');
      }
    },

    exportToExcel(){
      const filteredData = this.gridDataSource.map(row => {
        // Create a new object excluding the 'Id' field
        // eslint-disable-next-line no-unused-vars
        const { ProjectId, ...rest } = row;
        return rest;
      });
      // Convert filtered data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(filteredData);

      // Set custom headers
      const headers = this.columns
      .filter(col => col.field !== 'ProjectId')
      .map(col => col.header);
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Offer DataTable Export');

      // Export the workbook to an Excel file
      XLSX.writeFile(workbook, 'offer_datatable.xlsx');
    },
       
    goToProjectDashboard(args){
      const projectId = args.data.ProjectId;
      this.$router.push({
        path:"/projects/dashboard/" + projectId,
        params:projectId
      });
    }
  },
}
</script>

<style scoped>
.headerLabel{
  display: flex;
  justify-content: space-between; /* Space between text and filter button */
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

::v-deep .p-multiselect-label{
  display: flex;
  justify-content: space-between; /* Space between text and filter button */
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

::v-deep .p-datatable .p-datatable-tbody > tr > td {
  padding: 8px 21px !important;
}
</style>