export default {
  "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deny"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
  "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fetching..."])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "pdfExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF export"])},
  "resetReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset report"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
  "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
  "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
  "myProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My projects"])},
  "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead"])},
  "leads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leads"])},
  "myLeads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My leads"])},
  "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "closedLeads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed leads"])},
  "closedProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed projects"])},
  "query builder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis"])},
  "project dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Dashboard"])},
  "company dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Dashboard"])},
  "person dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person Dashboard"])},
  "contact dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Dashboard"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "termsAndPrivacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By logging in you accept the terms and privacy remark."])},
  "learnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
  "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
  "googleAuthenticatorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To set up multi-factor authentication on your mobile device, you need to download a compatible program there, such as Google Authenticator (https://googleauthenticator.net/) from the Play or Apple Store."])},
  "scanQRCodeInAuthenticator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To add your account, open the installed authenticator and select e.g. on Google Authenticator: Scan QR code and hold your camera over the QR code shown below."])},
  "manuelConnectionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there are any problems and the QR code is not accepted, you can also enter the following setup key manually."])},
  "loginProcessWarningForMfaCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have successfully added your account in the Authenticator, confirm this popup in the upper right corner and enter a code displayed by the Authenticator in the subsequent window for verification purposes"])},
  "warningDifferentCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that after your account has been successfully linked, another code is required from the authenticator in the subsequent login window. This must not be the same code you used in the previous step."])},
  "copyingKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy key"])},
  "codeDoesntMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code does not match."])},
  "enterMfaToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your MFA Token to Login."])},
  "passwordChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password was changed!"])},
  "newPasswordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new password is required, please enter your new password"])},
  "verifyMfaToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify your MFA Device by entering the provided code"])},
  "resetPasswordText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your domain Id & user name to start the reset password process. If this is your first login, you will find your user name inside the welcome email."])},
  "verificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification code"])},
  "enterVerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the verification code sent to your email address: "])},
  "strongNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a strong, new password"])},
  "aboutUsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is an about page"])},
  "openPopup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open popup"])},
  "openSince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open since "])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "responsible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsible"])},
  "equipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipments"])},
  "hasEquipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has Equipments"])},
  "hasNoEquipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has no Equipments"])},
  "existingCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existing customer"])},
  "interested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interested"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
  "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
  "createdOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created on"])},
  "openReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open reminder"])},
  "prio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority"])},
  "phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phase"])},
  "correspondence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondence"])},
  "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer"])},
  "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offers"])},
  "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "searchPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search person"])},
  "searchCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search company"])},
  "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])},
  "lastVisitedPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last visited person"])},
  "leadHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead-History"])},
  "projectHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project-History"])},
  "leadConversion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead-Conversion"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "landline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landline"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "creationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation date"])},
  "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
  "placeholder.domainId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input domain Id"])},
  "placeholder.username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input username"])},
  "placeholder.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input password"])},
  "placeholder.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
  "placeholder.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select..."])},
  "placeholder.enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter..."])},
  "placeholder.responsible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a responsible"])},
  "placeholder.company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a company"])},
  "placeholder.equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select equipment"])},
  "placeholder.equipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select equipments"])},
  "placeholder.selectLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select language"])},
  "placeholder.reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select reminder"])},
  "placeholder.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input title"])},
  "placeholder.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please write your description here"])},
  "placeholder.sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])},
  "placeholder.phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input phase"])},
  "placeholder.interested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input interested"])},
  "placeholder.prio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select prio"])},
  "placeholder.visibleTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input visible to"])},
  "placeholder.selectMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select members"])},
  "placeholder.budget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input budget "])},
  "placeholder.offerValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input offer value"])},
  "placeholder.offerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select offer type"])},
  "placeholder.bonusOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input bonus option value"])},
  "placeholder.probability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select probability in %"])},
  "placeholder.step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Step"])},
  "placeholder.customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select customer"])},
  "placeholder.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input name"])},
  "placeholder.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input email"])},
  "placeholder.location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select location"])},
  "placeholder.address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input address"])},
  "placeholder.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input country"])},
  "placeholder.phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input phone number"])},
  "placeholder.website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input website"])},
  "placeholder.category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input category"])},
  "placeholder.responsibleInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input responsible"])},
  "placeholder.taxId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input tax id"])},
  "placeholder.person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select person"])},
  "placeholder.searchPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search person"])},
  "placeholder.searchCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search company"])},
  "placeholder.searchClosedLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search lead"])},
  "placeholder.searchClosedProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search project"])},
  "placeholder.selectContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select contact"])},
  "placeholder.phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input mobile"])},
  "placeholder.landline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input landline"])},
  "placeholder.contactName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input contact name"])},
  "placeholder.enterComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter text..."])},
  "placeholder.selectProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select project"])},
  "placeholder.selectTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select task"])},
  "placeholder.displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input display name"])},
  "placeholder.personNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input person number"])},
  "placeholder.position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input position"])},
  "placeholder.salutations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select salutations"])},
  "placeholder.firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input first name"])},
  "placeholder.lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input last name"])},
  "placeholder.mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input mobile"])},
  "placeholder.department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input department"])},
  "placeholder.building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input building"])},
  "placeholder.birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input birthday"])},
  "placeholder.notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input notes"])},
  "placeholder.commissionNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input commission number"])},
  "placeholder.commissionValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input commission value"])},
  "placeholder.orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input order number"])},
  "placeholder.orderValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input order value"])},
  "placeholder.referenceNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input reference number"])},
  "placeholder.supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select supplier"])},
  "placeholder.offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select offer"])},
  "placeholder.type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select type"])},
  "placeholder.assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select assignment"])},
  "placeholder.amountPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input amount paid (Euro)"])},
  "placeholder.competitionModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input competition model"])},
  "placeholder.machineModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input machine model"])},
  "placeholder.discountPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input discount in %"])},
  "placeholder.offerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input offer number"])},
  "placeholder.machineNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input machine number"])},
  "swal.deleteLeadHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete lead"])},
  "swal.deleteLeadText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this lead ?"])},
  "swal.convertLeadHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convert lead"])},
  "swal.convertLeadText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to convert this lead into a project ?"])},
  "swal.closeLeadHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Lead"])},
  "swal.closeLeadText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel this lead ?"])},
  "swal.deleteReminderHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete reminder"])},
  "swal.deleteReminderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this reminder ?"])},
  "swal.leadCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead created"])},
  "swal.leadDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead deleted"])},
  "swal.leadConverted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead converted"])},
  "swal.leadClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead closed"])},
  "swal.reopenLeadHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen lead"])},
  "swal.reopenLeadText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to reopen this lead ?"])},
  "swal.leadReopened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead reopened"])},
  "swal.remindersEdited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminders edited"])},
  "swal.reminderDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminder deleted"])},
  "swal.projectCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project created"])},
  "swal.rejectedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have rejected"])},
  "swal.deleteProjectHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete project"])},
  "swal.deleteProjectText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this project and everything related to it? (offers, reports, etc.)"])},
  "swal.projectDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project deleted"])},
  "swal.closeProjectHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close project"])},
  "swal.closeProjectText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to close this project ?"])},
  "swal.projectClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project closed"])},
  "swal.reopenProjectHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen project"])},
  "swal.reopenProjectText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to reopen this project ?"])},
  "swal.projectReopened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project reopened"])},
  "swal.projectEdited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project edited"])},
  "swal.noMembersSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are required to add at least 1 member"])},
  "swal.deleteTaskHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete task"])},
  "swal.deleteTaskText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this task ?"])},
  "swal.taskDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task deleted"])},
  "swal.filteredPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondences and Tasks are being filtered by: "])},
  "swal.closeTaskHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close task"])},
  "swal.closeTaskText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to close this task ?"])},
  "swal.taskClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task closed"])},
  "swal.reopenTaskHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen Task"])},
  "swal.reopenTaskText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to reopen this task ?"])},
  "swal.taskReopened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task reopened"])},
  "swal.closeOfferHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close offer"])},
  "swal.closeOfferText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to close this offer ?"])},
  "swal.offerClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer closed"])},
  "swal.offerCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer created"])},
  "swal.offerEdited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer edited"])},
  "swal.deleteOfferHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete offer"])},
  "swal.deleteOfferText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this offer ?"])},
  "swal.offerDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer deleted"])},
  "swal.reopenOfferHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen offer"])},
  "swal.reopenOfferText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you wnt to reopen this offer ?"])},
  "swal.offerReopened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer reopened"])},
  "swal.provideLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide your log in information."])},
  "swal.copiedMFAKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied MFA-Key"])},
  "swal.logoutHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "swal.logoutText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to logout ?"])},
  "swal.changingLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changing language..."])},
  "swal.noSalesLicense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not own a sales license."])},
  "swal.correspondenceAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondence added"])},
  "swal.correspondenceEdited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondence edited"])},
  "swal.fetchingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fetching data"])},
  "swal.dataUpToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data successfully fetched"])},
  "swal.reportSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit report was saved"])},
  "swal.resetReportHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset visit report"])},
  "swal.resetReportText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to reset all changes made? (If you have already saved your report, these changes will not be reset!)"])},
  "swal.reportReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit report has been reset"])},
  "swal.taskAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task added"])},
  "swal.taskEdited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task edited"])},
  "swal.takeOverLeadTagsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take over lead tags"])},
  "swal.takeOverLeadTagsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to take over the lead classification and tags into the project?"])},
  "swal.leadEdited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead edited"])},
  "swal.saleCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale created"])},
  "swal.saleEdited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale edited"])},
  "swal.closeSaleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close offer"])},
  "swal.closeSaleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to close this sale ?"])},
  "swal.saleClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale closed"])},
  "swal.deleteSaleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete offer"])},
  "swal.deleteSaleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this sale ?"])},
  "swal.saleDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale deleted"])},
  "swal.contactCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact created"])},
  "swal.deleteCorrespondenceHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Correspondence"])},
  "swal.deleteCorrespondenceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this correspondence ?"])},
  "swal.correspondenceDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondence deleted"])},
  "swal.deleteFileHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete file"])},
  "swal.deleteFileText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this file ?"])},
  "swal.fileDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File deleted"])},
  "swal.submitWithoutUploadingHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files not uploaded"])},
  "swal.submitWithoutUploadingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some files have been selected but not uploaded, do you still want to continue ?"])},
  "swal.deleteReportHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete report"])},
  "swal.deleteReportText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this report ?"])},
  "swal.reportDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report deleted"])},
  "swal.reportCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report created"])},
  "swal.deleteReportWarningHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete associated report ?"])},
  "swal.deleteReportWarningText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By deleting this post, the corresponding report will also be deleted. Do you still want to delete this post ?"])},
  "swal.taskCommentAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment has been added"])},
  "swal.leadContactIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The last contact occurred in the Lead: "])},
  "swal.noContactInitiated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No contact has been made yet"])},
  "swal.errorWhenRoutingToUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred when opening the user. Please use the searchbar."])},
  "swal.allDataReceived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All data has already been received."])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
  "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
  "noChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No changes"])},
  "favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites"])},
  "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold"])},
  "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replaced"])},
  "lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lost"])},
  "delayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delayed"])},
  "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
  "noRealization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No realization"])},
  "postponed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postponed"])},
  "stopped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stopped"])},
  "newCorrespondence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Correspondence"])},
  "newLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New lead"])},
  "editLeads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit leads"])},
  "newProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New project"])},
  "editProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit project"])},
  "closeProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close project"])},
  "addTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add task"])},
  "editTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit task"])},
  "viewTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View task"])},
  "createLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create lead"])},
  "createProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create project"])},
  "createCorrespondence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create correspondence"])},
  "selectCorrespondenceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select correspondence type"])},
  "firstReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminder"])},
  "leadReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead reminder"])},
  "editCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit customer"])},
  "cardView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card view"])},
  "tableView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table view"])},
  "leadsCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leads created"])},
  "leadsClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leads closed"])},
  "projectsCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects created"])},
  "projectsClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects closed"])},
  "offersValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offers value"])},
  "newContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New contact"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtering"])},
  "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
  "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "filesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filesize"])},
  "lastModified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last modified"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "expiresAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expires at"])},
  "expiresIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expires in"])},
  "expiredSince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired since"])},
  "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display name"])},
  "task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task"])},
  "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
  "taskCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task count"])},
  "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changelog"])},
  "projectCorrespondences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project correspondences"])},
  "createNewCorrespondence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new correspondence"])},
  "createNewTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new task"])},
  "createNewOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new offer"])},
  "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload image"])},
  "uploadFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload files"])},
  "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])},
  "persons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persons"])},
  "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
  "employeeCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee count"])},
  "correspondenceCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondence count"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "contactCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact count"])},
  "followingChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has made the following changes:"])},
  "uploadedFollowingFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has uploaded the following files:"])},
  "createVisitReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create visit report"])},
  "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
  "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
  "visitReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit report"])},
  "visitReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit reports"])},
  "phoneReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone report"])},
  "phoneReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone reports"])},
  "newVisitReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New visit report"])},
  "createReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create report"])},
  "createPhoneReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create phone report"])},
  "newPhoneReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New phone report"])},
  "reportName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report name"])},
  "reportType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report type"])},
  "reportNameInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name of the report should not contain a Dot! i.e. Report.YourName. Please use the '_' symbol instead, i.e. Report_YourName"])},
  "createContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new Contact"])},
  "selectReportType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select report type"])},
  "newReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New report"])},
  "deliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery date"])},
  "offerDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer date"])},
  "budget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budget in €"])},
  "offerValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer value in €"])},
  "offerValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer values"])},
  "offerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer type"])},
  "bonusOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option in €"])},
  "probability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probability in %"])},
  "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step"])},
  "projectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project title"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "companyNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company number"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
  "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "taxId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax id"])},
  "leadCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead count"])},
  "projectCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project count"])},
  "offerCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer count"])},
  "saleCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale count"])},
  "createNewNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new notification"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "noNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No notifications available"])},
  "markAllAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark all as read"])},
  "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])},
  "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
  "companyOpenLeadOrProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies with open leads or projects"])},
  "projectDoesntExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This project has been deleted and doesn't exist anymore!"])},
  "changePhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
  "projectPhases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project-Phases"])},
  "requirementPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requirement phase"])},
  "appointmentPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment phase"])},
  "offerPhaseWithoutSpecifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer phase without specifications"])},
  "offerPhaseWithSpecifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer phase with specifications"])},
  "negotiationPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negotiation phase"])},
  "projectDeliveryPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project delivery"])},
  "postProcessingPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post processing"])},
  "container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container"])},
  "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classification"])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
  "taskTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task tags"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis"])},
  "startQuery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start query"])},
  "taskComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task comments"])},
  "prime.config.startsWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starts with"])},
  "prime.config.endsWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ends with"])},
  "prime.config.contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contains"])},
  "prime.config.notContains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not contains"])},
  "prime.config.equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equals"])},
  "prime.config.notEquals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not equals"])},
  "prime.config.noFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Filter"])},
  "prime.config.clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
  "prime.config.today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "prime.config.accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "prime.config.reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "prime.config.upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "prime.config.choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
  "prime.config.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "authenticationOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication options"])},
  "authenticationHintPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password required. Choose authentication options to assign a new password Neues Passwort erforderlich."])},
  "authenticationHintMFA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MFA setup required. Choose authentication options to set up"])},
  "optionalParams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional parameter"])},
  "personNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person number"])},
  "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
  "salutations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salutations"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
  "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building"])},
  "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
  "mr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr."])},
  "mrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mrs."])},
  "diverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse"])},
  "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale"])},
  "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
  "createNewSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new sale"])},
  "commissionNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission number"])},
  "commissionValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission value"])},
  "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order number"])},
  "orderValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order value"])},
  "salesDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales date"])},
  "referenceNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference number"])},
  "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier"])},
  "createPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create PDF"])},
  "amountPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount Paid (Euro)"])},
  "paymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment date"])},
  "missingAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing amount (Euro)"])},
  "paymentDeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment deadline"])},
  "competitionModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competition model"])},
  "machineModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine model"])},
  "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
  "showPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all payments"])},
  "errorKeysNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following mandatory fields have not been filled: "])},
  "assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment"])},
  "retired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retired"])},
  "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["have been uploaded"])},
  "lastContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last contact"])},
  "manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manufacturer"])},
  "selectSomething": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Lead, Task, Offer, Sale or Correspondence, to display them here!"])},
  "correspondenceWithoutContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondence without selected contact"])},
  "loadMoreData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more data"])},
  "loadMoreClosedData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more closed data"])},
  "earliestLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Lead received:"])},
  "earliestProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last project received:"])},
  "gotAllLeads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got all leads"])},
  "gotAllProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got all projects"])},
  "showDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show description"])},
  "dataLoadedTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data loaded till: "])},
  "discountPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount in %"])},
  "offerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offernumber"])},
  "timespan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timespan"])},
  "loadNext6Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load next 6 months"])},
  "loadContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load contacts"])},
  "maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximize"])},
  "minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimize"])},
  "machineNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine number"])},
  "markedFieldsAreMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields marked with a '*' are mandatory"])}
}