<template>
<div style="min-height: 600px">
    <div class="col-lg-12 control-section">
      <div class="content-wrapper">
        <DataTable
          ref="dataTable"
          :value="gridDataSource"
          paginator
          :rows="20"
          :rowsPerPageOptions="[10, 20, 50]"
          :scrollable="true"
          dataKey="Id"
          removableSort
          v-model:filters="filter"
          filterDisplay="menu"
          :globalFilterFields="globalFilter">
          <template #header>
            <div class="flex xl:justify-content-end align-items-center">
              <div v-if="lastEvaluatedKey" class="flex align-items-center gap-2">
                <PrimeButton style="height:36px;"
                            class="mr-2"
                            outlined
                            size="small"
                            severity="secondary"
                            @click="getMoreProjects">
                  {{$t('loadMoreData')}}
                </PrimeButton>
              </div>

            <PrimeButton style="height:36px;min-width:36px"
                          label="Excel"
                          icon="pi pi-file-excel"
                          class="mr-2"
                          outlined
                          severity="secondary"
                          @click="exportToExcel" />

              <span
                    class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filter['global'].value"
                          :placeholder="this.$t('placeholder.search')"
                          />
              </span>
            </div>
          </template>
          <Column v-for="(col,index) of columns"
            :key="col.field + '_' + index" :field="col.field"
            :filterField="col.field"
            :columnKey="col.field"
            :showFilterMatchModes="false"
            sortable
            :style="{minWidth: '100px'}"
            style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">
            <template #header>
              <span v-tooltip.top="col.header"
                class="headerLabel">{{ col.header }}</span>
            </template>
            <template #body="{data, field}">
              <span v-tooltip.top="data[field]">{{ data[field] }}</span>
            </template>
            <template v-if="filter" #filter="{ filterModel}">
              <MultiSelect
                class="p-column-filter"
                v-model="filterModel.value"
                :options="getColumnFilterOptions(col.field)"
                placeholder="any"
                filter
                autoFilterFocus>
                <template #option="slotProps">
                  <div class="flex align-items-center gap-2">
                    <span>{{slotProps.option}}</span>
                  </div>
                </template>
              </MultiSelect>
            </template>
          </Column>
          <Column v-for="(col,index) of projectTagColumns"
            :key="col.field + '_' + index" :field="col.field"
            :filterField="col.field"
            :showFilterMatchModes="false"
            :columnKey="col.field"
            sortable
            :style="{minWidth: '100px'}"
            style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">
            <template #header>
              <span v-tooltip.top="col.header"
                class="headerLabel">{{ col.header }}</span>
            </template>
            <template #body="{data, field}">
              <span v-tooltip.top="data[field]">{{ data[field] }}</span>
            </template>
            <template #filter="{ filterModel}">
              <MultiSelect
                v-if="filter"
                v-model="filterModel.value"
                :options="getColumnFilterOptions(col.field)"
                placeholder="any"
                filter
                autoFilterFocus>
                <template #option="slotProps">
                  <div class="flex align-items-center gap-2">
                    <span style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">{{slotProps.option}}</span>
                  </div>
                </template>
              </MultiSelect>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import QueryBuilderFunctions from "@/mixins/query-builder-functions/queryBuilderFunctions";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
import PrimeButton from "primevue/button";
import * as XLSX from 'xlsx';
import { FilterMatchMode } from "primevue/api";

export default {
  name: "ProjectQueryTable",
  mixins:[QueryBuilderFunctions],
  data() {
    return { 
      columns:[
        { field: 'Title', header: this.$t('title') },
        { field: 'Responsible', header: this.$t('responsible') },
        { field: 'Company', header: this.$t('company') },
        { field: 'Creator', header: this.$t('creator') },
        { field: 'Description', header: this.$t('description') },
        { field: 'Phase', header: this.$t('phase') },
        { field: 'ProjectType', header: this.$t('type') },
        { field: 'ProjectClassification', header: this.$t('classification') },
        { field: 'CreationDate', header: this.$t('creationDate') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title:{value: null, matchMode: FilterMatchMode.IN},
        Responsible:{value: null, matchMode: FilterMatchMode.IN},
        Company:{value: null, matchMode: FilterMatchMode.IN},
        Creator:{value: null, matchMode: FilterMatchMode.IN},
        Description:{value: null, matchMode: FilterMatchMode.IN},
        Phase:{value: null, matchMode: FilterMatchMode.IN},
        ProjectType:{value: null, matchMode: FilterMatchMode.IN},
        ProjectClassification:{value: null, matchMode: FilterMatchMode.IN},
        CreationDate:{value: null, matchMode: FilterMatchMode.IN},
      },
      globalFilter:['Title', 'Responsible', 'Company', 'Creator', 'Description', 'Phase', 'ProjectType', 'ProjectClassification', 'CreationDate'],
      projectTagColumns: [],
    };
  },

  components: {
    DataTable, Column, MultiSelect, InputText, PrimeButton,
  },
  computed:{
    gridDataSource(){
      return this.prepareDataSource(this.$store.getters.projects);
    },

    getColumnFilterOptions() {
      return (field) => {
        // Extract unique values for the given field
        const uniqueValues = new Set(
          this.gridDataSource.map(item => item[field]).filter(value => value != null) // filter out null/undefined
        );
        return Array.from(uniqueValues).map(value => ( value ));
      };
    },

    lastEvaluatedKey(){
      return this.$store.getters.openProjectsLastEvaluatedKey;
    }
  },
  methods: {
    prepareDataSource(projects){
      const src = [];
      const tags = this.$store.getters.allCustomLists;
     
      for (let project of projects){
        let obj = {};
        obj["Title"] = project["Title"];
        obj["Creator"] = project["Creator"];
        obj["Description"] = this.stripHtmlTags(project["Description"]);
        obj["Prio"] = project["Prio"];
        obj["ProjectType"] = project["ProjectType"] ? project["ProjectType"] : "-";
        obj["Id"] = project["Id"];

        // the table cannot display nested values so we modify / prepare the data.
        obj["Company"] = this.getValFromObject(project.Company, "Name") + " " + this.getValFromObject(project.Company, "CompanyNumber");
        obj["Responsible"] = this.getValFromObject(project.Responsible, "Name");
        obj["Phase"] =  this.getValFromObject(project.Phase, "Name");
        obj["ProjectClassification"] = this.getValFromObject(project.ProjectTagContainer, "ContainerName");

        if(project.ProjectTags && Object.keys(project.ProjectTags).length > 0){
          for(let tag of tags){
            if(project.ProjectTags[tag.Id]){
              obj[tag.ClusterName] = project.ProjectTags[tag.Id].toString();
            }
          }
        }

        obj["CreationDate"] = new Date(Number(project.Created)).toLocaleDateString();

        src.push(obj);
      }
      return src;
    },

    stripHtmlTags(htmlString){
      return htmlString.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ');
    },

    goToProjectDashboard(args){
      const projectId = args.data.Id;
      this.$router.push({
        path:"/projects/dashboard/" + projectId,
        params:projectId
      });
    },
    
    initializeProjectTagFilter() {
      this.projectTagColumns.forEach((col) => {
        this.filter[col.field] = {value: null, matchMode: FilterMatchMode.IN};  // Direct assignment to filter object
        this.globalFilter.push(col.header);
      });
    },

    createTagsColumns(){
      const projectTags = [];
      const tags = this.$store.getters.allCustomLists;
      const projectContainers = this.$store.getters.allTagContainers.filter((container) => container.Coverage === 'Projects');
      for(let container of projectContainers){
        for(let tag of container.Tags){
          const foundProjectTag = tags.find((currentTag) => currentTag.Id === tag);
          if(foundProjectTag){
            const tagAlreadyExists = projectTags.some(tag => tag.header === foundProjectTag.ClusterName);
            if(!tagAlreadyExists){
              projectTags.push({
                header: foundProjectTag.ClusterName,
                field:foundProjectTag.ClusterName
              });
            }
          }
        }
      }
      return projectTags;
    },

    exportToExcel(){
      const filteredData = this.gridDataSource.map(row => {
        // Create a new object excluding the 'Id' field
        // eslint-disable-next-line no-unused-vars
        const { Id, ...rest } = row;
        return rest;
      });
      // Convert filtered data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(filteredData);

      // Set custom headers
      const headers = this.columns
        .filter(col => col.field !== 'Id')
        .map(col => col.header);
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Project DataTable Export');

      // Export the workbook to an Excel file
      XLSX.writeFile(workbook, 'project_datatable.xlsx');
    },
    
    getMoreProjects(){
      if(this.lastEvaluatedKey && this.lastEvaluatedKey.Created && this.lastEvaluatedKey.Id){
        const created = Number(this.lastEvaluatedKey["Created"]);
        const id = this.lastEvaluatedKey["Id"];
        this.$store.dispatch("getRequest","getMoreProjects&query=" + [id, created]).then(resp => {
          if (resp && resp.statusCode === 200) {
            const body = JSON.parse(resp.body);
            this.$toast.add({ severity: 'success', summary: this.$t('done'), detail: this.$t('swal.dataUpToDate'), life: 3000 });
            this.$store.commit('setOpenProjects', body.Items);
            this.prepareDataSource(body.Items);

            if (body.LastEvaluatedKey && body.Items.length > 0) {
              this.$store.commit('setOpenProjectsLastEvaluatedKey', body.LastEvaluatedKey);
            }else{
              this.$store.commit('setOpenProjectsLastEvaluatedKey', null);
            }
          }else{
            this.$toast.add({ severity: 'error', summary: this.$t('Info'), detail: this.$t('swal.allDataReceived'), life: 5000 });
          }
        });
      }
    },
  },
  created(){
    this.projectTagColumns = this.createTagsColumns();
    this.initializeProjectTagFilter();
  },
};
</script>

<style scoped>
.headerLabel{
  display: flex;
  justify-content: space-between; /* Space between text and filter button */
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

::v-deep .p-multiselect-label{
  display: flex;
  justify-content: space-between; /* Space between text and filter button */
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

::v-deep .p-datatable .p-datatable-tbody > tr > td {
  padding: 8px 21px !important;
}
</style>
