<template>
  <div style="min-height: 600px">
    <div class="col-lg-12 control-section">
      <div class="content-wrapper">
        <DataTable
          ref="dataTable"
          :value="gridDataSource"
          paginator
          :rows="20"
          :rowsPerPageOptions="[10, 20, 50]"
          :scrollable="true"
          dataKey="Id"
          removableSort
          selectionMode="single"
          v-model:filters="filter"
          filterDisplay="menu"
          :globalFilterFields="globalFilter"
          @rowSelect="goToCompanyDashboard">
          <template #header>
            <div class="flex xl:justify-content-end align-items-center">
              <div v-if="lastEvaluatedKey" class="flex align-items-center gap-2">
                <PrimeButton style="height:36px;"
                            class="mr-2"
                            outlined
                            size="small"
                            severity="secondary"
                            @click="getMoreCompanies">
                  {{$t('loadMoreData')}}
                </PrimeButton>
              </div>

            <PrimeButton style="height:36px;min-width:36px"
                          label="Excel"
                          icon="pi pi-file-excel"
                          class="mr-2"
                          outlined
                          severity="secondary"
                          @click="exportToExcel" />

              <span
                    class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filter['global'].value"
                          :placeholder="this.$t('placeholder.search')"
                          />
              </span>
            </div>
          </template>
          <Column v-for="(col,index) of columns"
            :key="col.field + '_' + index" :field="col.field"
            :filterField="col.field"
            :columnKey="col.field"
            :showFilterMatchModes="false"
            sortable
            :style="{minWidth: '100px'}"
            style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">
            <template #header>
              <span v-tooltip.top="col.header"
                class="headerLabel">{{ col.header }}</span>
            </template>
            <template #body="{data, field}">
              <span v-tooltip.top="data[field]">{{ data[field] }}</span>
            </template>
            <template v-if="filter" #filter="{ filterModel}">
              <MultiSelect
                class="p-column-filter"
                v-model="filterModel.value"
                :options="getColumnFilterOptions(col.field)"
                placeholder="any"
                filter
                autoFilterFocus>
                <template #option="slotProps">
                  <div class="flex align-items-center gap-2">
                    <span>{{slotProps.option}}</span>
                  </div>
                </template>
              </MultiSelect>
            </template>
          </Column>
          <Column v-for="(col,index) of companyTagColumns"
            :key="col.field + '_' + index" :field="col.field"
            :filterField="col.field"
            :showFilterMatchModes="false"
            :columnKey="col.field"
            sortable
            :style="{minWidth: '100px'}"
            style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">
            <template #header>
              <span v-tooltip.top="col.header"
                class="headerLabel">{{ col.header }}</span>
            </template>
            <template #body="{data, field}">
              <span v-tooltip.top="data[field]">{{ data[field] }}</span>
            </template>
            <template #filter="{ filterModel}">
              <MultiSelect
                v-if="filter"
                v-model="filterModel.value"
                :options="getColumnFilterOptions(col.field)"
                placeholder="any"
                filter
                autoFilterFocus>
                <template #option="slotProps">
                  <div class="flex align-items-center gap-2">
                    <span style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">{{slotProps.option}}</span>
                  </div>
                </template>
              </MultiSelect>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import PrimeButton from 'primevue/button';
import { FilterMatchMode } from 'primevue/api';
import * as XLSX from 'xlsx';

export default {
  name: "CompanyQueryTable",
  data() {
    return {
      columns:[
        { field: 'Name', header: this.$t('name') },
        { field: 'City', header: this.$t('city') },
        { field: 'Address', header: this.$t('address') },
        { field: 'Phone', header: this.$t('phone') },
        { field: 'Responsible', header: this.$t('responsible') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Name:{value: null, matchMode: FilterMatchMode.IN},
        City:{value: null, matchMode: FilterMatchMode.IN},
        Address:{value: null, matchMode: FilterMatchMode.IN},
        Phone:{value: null, matchMode: FilterMatchMode.IN},
        Responsible:{value: null, matchMode: FilterMatchMode.IN},
      },
      globalFilter: ['Name', 'City', 'Address', 'Phone', 'Responsible'],
      companyTagColumns: [],
    };
  },
  components: {
    DataTable, Column, MultiSelect, InputText, PrimeButton,
  },
  computed:{
    toolbarOptions(){
      const options = ['ExcelExport'];

      if(this.lastEvaluatedKey){
        console.log(this.lastEvaluatedKey);
        options.push(
          {text: this.$t('loadMoreData'),
          tooltipText: 'Load more data',
          prefixIcon: 'e-refresh',
          id: 'toolbar_load',
          align: 'Right' });
      }
      return options;
    },

    gridDataSource(){
      return this.prepareDataSource(this.$store.getters.companyQueryData);
    },

    getColumnFilterOptions() {
      return (field) => {
        // Extract unique values for the given field
        const uniqueValues = new Set(
          this.gridDataSource.map(item => item[field]).filter(value => value != null) // filter out null/undefined
        );
        return Array.from(uniqueValues).map(value => ( value ));
      };
    },

    lastEvaluatedKey(){
      return this.$store.getters.companyLastEvaluatedKey;
    }
  },
  methods: {
    prepareDataSource(companies){
      const src = [];
      for(let company of companies){
        let obj = {};
        obj["Name"] = company["Name"];
        obj["City"] = company["City"];
        obj["Address"] = company["Address"];
        obj["Phone"] = company["Phone"];
        obj["Responsible"] = company["Responsible"];
        obj["Id"] = company["Id"];
        if(company.Tags && company.Tags.length > 0){
          for(let tag of company.Tags){
            obj[tag.labelName] = tag.selectedValue.toString();
          }
        }
        src.push(obj);
      }
      return src;
    },

    goToCompanyDashboard(args){
      const companyId = args.data.Id;
      this.$router.push({
        path:"/companies/dashboard/" + companyId,
        params:companyId
      });
    },

    initializeCompanyTagFilter() {
      this.companyTagColumns.forEach((col) => {
        this.filter[col.field] = {value: null, matchMode: FilterMatchMode.IN};  // Direct assignment to filter object
        this.globalFilter.push(col.header);
      });
    },

    createTagsColumns(){
      const companyTags = [];
      const tags = this.$store.getters.allCustomLists;
      const companyContainers = this.$store.getters.allTagContainers.filter((container) => container.Coverage === 'Companies');
      for(let container of companyContainers){
        for(let tag of container.Tags){
          const foundCompanyTag = tags.find((currentTag) => currentTag.Id === tag);
          if(foundCompanyTag){
            const tagAlreadyExists = companyTags.some(tag => tag.header === foundCompanyTag.ClusterName);
            if(!tagAlreadyExists){
              companyTags.push({
                header: foundCompanyTag.ClusterName,
                field:foundCompanyTag.ClusterName
              });
            }
          }
        }
      }
      return companyTags;
    },

    
    exportToExcel(){
      const filteredData = this.gridDataSource.map(row => {
        // Create a new object excluding the 'Id' field
        // eslint-disable-next-line no-unused-vars
        const { Id, ...rest } = row;
        return rest;
      });
      // Convert filtered data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(filteredData);

      // Set custom headers
      const headers = this.columns
        .filter(col => col.field !== 'Id')
        .map(col => col.header);
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Company DataTable Export');

      // Export the workbook to an Excel file
      XLSX.writeFile(workbook, 'company_datatable.xlsx');
    },

    // Here we get the equipment data from the server and return it
    getCompaniesData(){
      if(this.gridDataSource.length === 0){
        this.$store.dispatch('getRequest',"getAdminCompanies").then((data)=>{
          if(data && data.statusCode && data.statusCode === 200){
            const body = JSON.parse(data.body);
            this.$store.commit('setCompanyLastEvaluatedKey', body["LastEvaluatedKey"]);
            this.$store.commit('setCompanyQueryData', body.Items);
          }
        });
      }
    },

    getMoreCompanies(){
      this.$store.dispatch('getRequest',"getMoreAdminCompanies&query=" + this.lastEvaluatedKey["Id"]).then((data)=>{
        if(data && data.statusCode && data.statusCode === 200){
          const body = JSON.parse(data.body);
          this.$toast.add({ severity: 'success', summary: this.$t('done'), detail: this.$t('swal.dataUpToDate'), life: 3000 });
          this.$store.commit('setCompanyLastEvaluatedKey', body["LastEvaluatedKey"]);
          this.$store.commit('setCompanyQueryData', body['Items']);
        }
      });
    },
  },

  created(){
    this.getCompaniesData();
    this.companyTagColumns = this.createTagsColumns();
    this.initializeCompanyTagFilter();
  },
}
</script>

<style scoped>
.headerLabel{
  display: flex;
  justify-content: space-between; /* Space between text and filter button */
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

::v-deep .p-multiselect-label{
  display: flex;
  justify-content: space-between; /* Space between text and filter button */
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

::v-deep .p-datatable .p-datatable-tbody > tr > td {
  padding: 8px 21px !important;
}
</style>