<template>
  <TabView>
    <TabPanel :header="this.$t('open') + ' (' + projects.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'openProjects'"
                     :style="{'height': windowHeight}"
                     :data-prop="projects"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :filter-prop="filter"
                     :show-delete-button="true"
                     @row-deleted="deleteProject"
                     :show-toggle-column="true"
                     :show-close-button="true"
                     @close-data="showCloseProjectPopup"
                     :sortable="true"
                     :has-removable-sort="true"
                     :filter-options="filterOptions"
                     :key="tableKey"
                     :selection-mode="'single'"
                     @row-selected="openProjectDashboard"
                     :resizable-columns="true"
                     :show-load-more-data="openProjectsLastEvaluatedKey"
                     @load-more-data="loadMoreProjects"
                     :close-button-tool-tip="$t('swal.closeProjectHeader')"
                     :delete-button-tool-tip="$t('swal.deleteProjectHeader')"/>
    </TabPanel>

    <TabPanel :header="this.$t('myProjects') + ' (' + myProjects.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'myProjects'"
                     :style="{'height': windowHeight}"
                     :data-prop="myProjects"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :filter-prop="filter"
                     :show-delete-button="true"
                     @row-deleted="deleteProject"
                     :show-toggle-column="true"
                     :show-close-button="true"
                     @close-data="showCloseProjectPopup"
                     :sortable="true"
                     :has-removable-sort="true"
                     :filter-options="filterOptions"
                     :key="tableKey"
                     :selection-mode="'single'"
                     @row-selected="openProjectDashboard"
                     :resizable-columns="true"
                     :show-load-more-data="openProjectsLastEvaluatedKey"
                     @load-more-data="loadMoreProjects"
                     :close-button-tool-tip="$t('swal.closeProjectHeader')"
                     :delete-button-tool-tip="$t('swal.deleteProjectHeader')"/>
    </TabPanel>

    <TabPanel :header="this.$t('closed') + ' (...)'">
      <div class="w-full"
           style="min-height:30vh" >
        <SearchableSelectRow :show-table="true"
                             :show-timespan="true"
                             :search-option="'ClosedProjects'"
                             :placeholder="this.$t('placeholder.searchClosedProject')"
                             @selection-changed="openProjectDashboard" />
      </div>
    </TabPanel>

    <TabPanel :header="this.$t('favorites') + ' (' + favorisedProjects.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'favoriteProjects'"
                     :data-prop="favorisedProjects"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :filter-options="filterOptions"
                     :filter-prop="filter"
                     :show-delete-button="true"
                     @row-deleted="deleteProject"
                     :style="{'height': windowHeight}"
                     :show-close-button="true"
                     @close-data="showCloseProjectPopup"
                     :sortable="true"
                     :has-removable-sort="true"
                     :show-toggle-column="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     @row-selected="openProjectDashboard"
                     :resizable-columns="true"
                     :close-button-tool-tip="$t('swal.closeProjectHeader')"
                     :delete-button-tool-tip="$t('swal.deleteProjectHeader')" />
    </TabPanel>
  </TabView>

  <Popup :maximizable="false"
         :modal="true"
         :visible="closingProject"
         :header="this.$t('swal.closeProjectText')"
         :show-save-button="true"
         @button-clicked="submitCloseProjectPopup"
         styling="width:500px"
         @close-popup="closeCloseProjectPopup">
    <CloseProject ref="closeProject"
                  @close-project="closeProject" />
  </Popup>
</template>

<script>
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import {FilterMatchMode} from "primevue/api";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Popup from "@/components/global-components/popup/Popup";
import SearchableSelectRow from "@/components/global-components/searchable-select-row/SearchableSelectRow";
import CloseProject from "@/components/projects/close-project/CloseProject";
import S3FileManager from "@/mixins/s3-file-manager/s3-file-manager";
import getStoreData from "@/mixins/get-store-data/getStoreData";

export default {
  name: "ProjectsComponent",
  components:{SearchableSelectRow, MaterialTable, Popup, CloseProject, TabView, TabPanel},
  mixins:[getStoreData, S3FileManager],
  data(){
    return{
      closingProject:false,
      columns:[
        { field: 'CreationNumber', header: this.$t('number') },
        { field: 'Title', header: this.$t('title') },
        { field: 'Description', header: this.$t('description') },
        { field: 'Creator', header: this.$t('creator') },
        { field: 'Responsible.Name', header: this.$t('responsible') },
        { field: 'Created', header: this.$t('createdOn') },
        { field: 'Company.Name', header: this.$t('company') },
        { field: 'OpenSince', header: this.$t('openSince') },
        { field: 'Location.LocationName', header: this.$t('location') },
        { field: 'Supplier', header: this.$t('supplier')},
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CreationNumber:{value: null, matchMode: FilterMatchMode.CONTAINS},
        Title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Creator: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Responsible.Name': { value: null, matchMode: FilterMatchMode.IN },
        Created: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Company.Name': { value: null, matchMode: FilterMatchMode.IN },
        OpenSince: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Location.LocationName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        Supplier: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['CreationNumber', 'Title', 'Description', 'Creator',
       'Responsible.Name', 'Company.Name', 'Supplier', 'Location.LocationName'],
      selectedProject: null,
      tableKey: 0,
      windowHeight: '',
    }
  },
  computed:{
    /**
     *
     * @returns {any}
     */
    projects(){
      const projects = [];
      for(let project of this.$store.getters.projects){
        if(project.Status === "10") projects.push(project);
      }
      return projects.sort((a, b) => {
        if (a['Created'] > b['Created']) return -1;
        if (a['Created'] < b['Created']) return 1;
      });
    },

    myProjects(){
      const myProjects = [];
      for(let project of this.projects){
        const foundMember = project.Members.filter(member => member.Id === this.$store.getters.username)[0];
        if(foundMember) myProjects.push(project);
        if(!foundMember){
          if(project.Responsible.Id === this.$store.getters.username) myProjects.push(project);
        }
      }
      return myProjects
    },

    favorisedProjects(){
      return this.$store.getters.favorisedProjects;
    },

    sortOptions(){
      return [
        { label: this.$t('title'), value: 'Title' },
        { label: this.$t('responsible'), value: 'Responsible.Name' },
        { label: this.$t('openSince'), value: 'Created' },
        { label: this.$t('creator'), value: 'Creator' },
      ]
    },

    openProjectsLastEvaluatedKey(){
      return this.$store.getters.openProjectsLastEvaluatedKey;
    }
  },
  methods:{
    openProjectDashboard(item){
      this.$router.push({
        path: '/project/dashboard/:Id',
        name: 'Project Dashboard',
        params: item,
      });
    },

    /**
     * Shows an alert that asks for confirmation to delete the project.
     * If we accept we show a short confirmation popup in the top-right corner and
     * remove the project from the store.
     * If we decline we show a short reject popup in the top-right corner.
     * @param project
     */
    deleteProject(project){
      this.$confirm.require({
        header: this.$t('swal.deleteProjectHeader'),
        message: this.$t('swal.deleteProjectText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.unfavoriseProject(project.Id);
          this.$store.dispatch('deleteProject',project).then(() => {
            this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.projectDeleted'), life: 3000 });
            this.deleteAllTasks();
            this.deleteAllOffers();
            this.deleteAllSales();
            this.deleteFolder('sales/leadsAndProjects/' + project.Id + '/');
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    deleteAllTasks(){
      for(let task of this.$store.getters.tasks){
        if(task.ProjectId === this.projectId){
          this.$store.dispatch('deleteTask',task);
        }
      }
    },

    deleteAllOffers(){
      for(let offer of this.$store.getters.offers){
        if(offer.ProjectId === this.projectId){
          this.$store.dispatch('deleteOffer',offer);
        }
      }
    },

    deleteAllSales(){
      for(let sale of this.$store.getters.sales){
        if(sale.ProjectId === this.projectId){
          this.$store.dispatch('deleteSale',sale);
        }
      }
    },

    showCloseProjectPopup(project){
      this.closingProject = true;
      this.selectedProject = project;
    },

    /**
     * Shows an alert that asks for confirmation to close the project.
     * If we accept we show a short confirmation popup in the top-right corner and
     * remove the project from the store and push them into the closedProject array.
     * If we decline we show a short reject popup in the top-right corner.
     * @param status
     */
    closeProject(status){
      this.selectedProject.Status = status;
      this.selectedProject.Closed = new Date().getTime();
      this.$store.dispatch('closeProject',this.selectedProject).then(() => {
        this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.projectClosed'), life: 3000 });
        this.unfavoriseProject(this.selectedProject.Id);
        this.closeCloseProjectPopup();
      });
    },

    unfavoriseProject(projectId){
      let favorisedProjectIds = JSON.parse(localStorage.getItem("favorisedProjects"));
      if(favorisedProjectIds){
        favorisedProjectIds = favorisedProjectIds.filter(favProjectId => favProjectId !== projectId);

        const favorisedProjects = this.favorisedProjects.filter(project => project.Id !== projectId);

        window.localStorage.setItem("favorisedProjects", JSON.stringify(favorisedProjectIds));
        this.$store.commit('setFavorisedProjects',favorisedProjects);
      }
    },

    submitCloseProjectPopup(){
      this.$refs.closeProject.emitCloseProject();
    },

    closeCloseProjectPopup(){
      this.closingProject = false;
      this.selectedProject = null;
    },

    loadMoreProjects(){
      this.getMoreProjects();
    }
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.66 + 'px';
  }
}
</script>

<style scoped>
:deep(.p-tabview-panels){
  padding-left:0 !important;
  padding-right:0 !important;
}
</style>