<template>
  <div class="flex justify-content-between gap-2 mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="this.$t('title')"
                 :input="salesData.Title">
      <InputText class="w-full"
                 v-model="salesData.Title"
                 :class="v$.salesData.Title.$error ? 'p-invalid' : ''"
                 :placeholder="this.$t('placeholder.title')"
                 maxlength="100" />
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('responsible')"
                 :input="salesData.Responsible ? salesData.Responsible.Name : null">
      <Dropdown class="w-full"
                v-model="salesData.Responsible"
                :class="v$.salesData.Responsible.$error ? 'p-invalid' : ''"
                :options="responsibleOptions"
                optionLabel="Name"
                filter
                :placeholder="this.$t('placeholder.responsible')"
                autoFilterFocus />
    </CustomLabel>
  </div>

  <div class="flex justify-content-between gap-2 mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="this.$t('commissionNumber')"
                 :input="salesData.CommissionNumber">
      <InputText class="w-full"
                 v-model="salesData.CommissionNumber"
                 :class="v$.salesData.CommissionNumber.$error ? 'p-invalid' : ''"
                 :placeholder="this.$t('placeholder.commissionNumber')"
                 maxlength="100" />
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('commissionValue')"
                 :input="salesData.CommissionValue">
      <InputNumber class="w-full"
                 v-model="salesData.CommissionValue"
                 @update:modelValue="calculateMissingAmount"
                 :minFractionDigits="2"
                 :maxFractionDigits="2"
                 :placeholder="this.$t('placeholder.commissionValue')"
                 :min="0" />
    </CustomLabel>
  </div>

  <div class="flex justify-content-between gap-2 mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="this.$t('orderNumber')"
                 :input="salesData.OrderNumber">
      <InputText class="w-full"
                 v-model="salesData.OrderNumber"
                 :placeholder="this.$t('placeholder.orderNumber')"
                 maxlength="100" />
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('orderValue')"
                 :input="salesData.OrderValue">
      <InputNumber class="w-full"
                 v-model="salesData.OrderValue"
                 :minFractionDigits="2"
                 :maxFractionDigits="2"
                 :placeholder="this.$t('placeholder.orderValue')"
                 :min="0" />
    </CustomLabel>
  </div>

  <div class="mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="this.$t('discountPercentage')"
                 :input="salesData.Discount">
      <InputNumber class="w-full"
                 v-model="salesData.Discount"
                 :minFractionDigits="2"
                 :maxFractionDigits="2"
                 :placeholder="this.$t('placeholder.discountPercentage')"
                 max="100"
                 />
    </CustomLabel>
  </div>

  <div class="flex justify-content-between gap-2 mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="this.$t('referenceNumber')"
                 :input="salesData.ReferenceNumber">
      <InputText class="w-full"
                 v-model="salesData.ReferenceNumber"
                 :placeholder="this.$t('placeholder.referenceNumber')"
                 maxlength="100" />
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('offerValue')"
                 :input="salesData.OfferValue">
      <InputNumber class="w-full"
                 v-model="salesData.OfferValue"
                 :minFractionDigits="2"
                 :maxFractionDigits="2"
                 :class="v$.salesData.OfferValue.$error ? 'p-invalid' : ''"
                 :placeholder="this.$t('placeholder.offerValue')"
                 :min="0" />
    </CustomLabel>
  </div>

  <div class="flex justify-content-between gap-2 mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="this.$t('salesDate')"
                 :input="salesData.SalesDate">
      <Calendar class="w-full"
                :modelValue="new Date(Number(salesData.SalesDate))"
                dateFormat="dd.mm.yy"
                showIcon
                hideOnDateTimeSelect
                @update:modelValue="setSalesUnix($event)"/>
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('deliveryDate')"
                 :input="salesData.DeliveryDate">
      <Calendar class="w-full"
                :modelValue="new Date(Number(salesData.DeliveryDate))"
                dateFormat="dd.mm.yy"
                showIcon
                hideOnDateTimeSelect
                @update:modelValue="setDeliveryUnix($event)"/>
    </CustomLabel>
  </div>

  <div class="flex justify-content-between gap-2 mb-2 mt-4">
    <CustomLabel class="w-full"
                 :label="this.$t('amountPaid')"
                 :input="currentPaymentEntry.AmountPaid || currentPaymentEntry.AmountPaid === 0">
      <InputNumber class="w-full"
                   v-model="currentPaymentEntry.AmountPaid"
                   @update:modelValue="setPayment"
                   :minFractionDigits="2"
                   :maxFractionDigits="2"
                   :placeholder="this.$t('placeholder.amountPaid')" />
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('missingAmount')"
                 :input="currentPaymentEntry.MissingAmount || currentPaymentEntry.MissingAmount === 0">
      <InputNumber class="w-full"
                   v-model="currentPaymentEntry.MissingAmount"
                   :disabled="true" />
    </CustomLabel>
  </div>

  <div class="flex justify-content-between gap-2 mb-4 mt-2">
    <CustomLabel class="w-full"
                 :label="this.$t('paymentDate')"
                 :input="currentPaymentEntry.PaymentDateUnix ||currentPaymentEntry.PaymentDateUnix === 0">
      <Calendar class="w-full"
                :modelValue="new Date(Number(currentPaymentEntry.PaymentDateUnix))"
                dateFormat="dd.mm.yy"
                showIcon
                hideOnDateTimeSelect
                @update:modelValue="setPaymentDateUnix($event)"/>
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="this.$t('paymentDeadline')"
                 :input="currentPaymentEntry.PaymentDeadlineUnix || currentPaymentEntry.PaymentDeadlineUnix === 0">
      <Calendar class="w-full"
                :modelValue="new Date(Number(currentPaymentEntry.PaymentDeadlineUnix))"
                dateFormat="dd.mm.yy"
                showIcon
                hideOnDateTimeSelect
                @update:modelValue="setPaymentDeadlineUnix($event)"/>
    </CustomLabel>
  </div>

  <div class="mb-2 mt-2">
    <ContactSection :company-id="project.Company.Id"
                    :is-mandatory="true"
                    :contact-options="contactOptions"
                    :contact-props="salesData.Contact"
                    :is-dropdown="true"
                    :show-load-button="true"
                    @contact-changed="setContact"
                    @get-contacts="getContactsByCompanyId"/>
  </div>

  <div class="flex justify-content-between gap-2 mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="this.$t('placeholder.visibleTo')"
                 :input="salesData.VisibleTo">
      <Dropdown class="w-full"
                v-model="salesData.VisibleTo"
                :options="visibleToOptions"
                :class="v$.salesData.VisibleTo.$error ? 'p-invalid' : ''"
                :placeholder="this.$t('placeholder.visibleTo')"/>
    </CustomLabel>

    <MultiSelect v-if="salesData.VisibleTo === 'Members'"
                 class="w-full"
                 v-model="salesData.Members"
                 :options="project.Members"
                 optionLabel="Name"
                 filter
                 :placeholder="this.$t('placeholder.selectMembers')"
                 autoFilterFocus/>
  </div>

  <div class="flex justify-content-between gap-2 mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="'Equipment'"
                 :input="salesData.Equipment ? salesData.Equipment.Name : ''">
      <MultiSelect class="w-full"
                   v-model="salesData.Equipment"
                   :options="equipments"
                   optionLabel="Name"
                   filter
                   :placeholder="this.$t('placeholder.equipment')"
                   autoFilterFocus/>
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="$t('supplier')"
                 :input="salesData.Supplier">
        <Dropdown class="w-full"
                  v-model="salesData.Supplier"
                  :options="supplierOptions"
                  :placeholder="$t('placeholder.supplier')" />
      </CustomLabel>
  </div>

  <div class="flex justify-content-between gap-2 mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="$t('competitionModel')"
                 :input="salesData.CompetitionModel">
      <InputText class="w-full"
                 v-model="salesData.CompetitionModel"
                 :placeholder="this.$t('placeholder.competitionModel')"
                 maxlength="100" />
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="$t('machineModel')"
                 :input="salesData.MachineModel">
      <InputText class="w-full"
                 v-model="salesData.MachineModel"
                 :placeholder="this.$t('placeholder.machineModel')"
                 maxlength="100"/>
    </CustomLabel>
  </div>

  <div class="flex justify-content-between gap-2 mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="$t('machineNumber')"
                 :input="salesData.MachineNumber">
      <InputText class="w-full"
                 v-model="salesData.MachineNumber"
                 :placeholder="this.$t('placeholder.machineNumber')"
                 maxlength="100"/>
    </CustomLabel>

    <CustomLabel class="w-full"
                 :label="$t('offer')"
                 :input="selectedOffer.Name">
      <Dropdown class="w-full"
                v-model="selectedOffer"
                :options="offerOptions"
                optionLabel="Name"
                filter
                :placeholder="this.$t('placeholder.offer')"
                autoFilterFocus/>
    </CustomLabel>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Calendar from "primevue/calendar";
import InputNumber from "primevue/inputnumber";
import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ContactSection from "@/components/global-components/contact-section/ContactSection";
import Uuidv4Generator from "@/mixins/uuidv4/Uuidv4Generator";

export default {
  name: "AddSale",
  components:{ContactSection, InputText, Dropdown, MultiSelect, Calendar, InputNumber, CustomLabel},
  props:{
    project:Object,
  },
  mixins:[Uuidv4Generator],
  emits:['addSale'],
  data(){
    return{
      salesData:{
        Closed:'',
        CommissionNumber:'',
        CommissionValue:null,
        Company:this.project.Company,
        CompanyId:this.project.Company.Id,
        CompetitionModel:'',
        Contact:{Id:"",Name:"",Email:"",Phone:"",Landline:""},
        ContactId:'-',
        DeliveryDate:new Date().getTime(),
        Discount:null,
        Domain:this.$store.getters.domainName,
        Equipment:'',
        Id:this.createUuidv4(),
        MachineModel:'',
        MachineNumber: '',
        Members:[],
        OfferId:'',
        OfferName:'',
        OfferValue:null,
        OrderNumber:'',
        OrderValue: null,
        Payments:[],
        ProjectId:this.project.Id,
        ReferenceNumber:'',
        RegisteredBy:this.$store.getters.shortUsername,
        Responsible:'',
        ResponsibleId:'-',
        SalesDate:new Date().getTime(),
        Status:'10',
        Supplier:'',
        Title:'',
        VisibleTo:'All',
      },
      selectedOffer:{},
      contactOptions:[],
      supplierOptions:[],
      currentPaymentEntry:{
        AmountPaid: 0,
        PaymentDateUnix: 0,
        MissingAmount: 0,
        PaymentDeadlineUnix: 0
      },
    }
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  validations(){
    return {
      salesData: {
        Title: { required },
        Responsible: { required },
        CommissionNumber: { required },
        OfferValue: { required },
        VisibleTo: { required },
      },
    }
  },

  computed:{
    responsibleOptions(){
      return this.$store.getters.responsibles.map(responsible => ({Name:responsible.FriendlyName,Id:responsible.User, type: "User"}));
    },

    equipments(){
      const equipments = this.$store.getters.equipments;
      const changedEquipmentData = [];
      for(let equipment of equipments){
        if(equipment.CompanyId === this.project.Company.Id){
          changedEquipmentData.push({Name:equipment.EquipmentName,Id:equipment.Id});
        }
      }
      return changedEquipmentData;
    },

    currentUserData(){
      let currentUser = {};
      const responsibles = this.$store.getters.responsibles;
      for(let responsible of responsibles){
        if(responsible.User === this.$store.getters.username){
          currentUser = responsible;
          break;
        }
      }

      return currentUser
    },

    visibleToOptions(){
      return ['Self','Members','All'];
    },

    offerOptions(){
      const offers = [];
      const allOffers = this.$store.getters.offers;
      for(let offer of allOffers){
        if(offer.Status === '20'){
          if(offer.ProjectId === this.project.Id) {
            offers.push({'Name':offer.Title, 'Id':offer.Id});
          }
        }
      }
      return offers;
    }
  },

  methods:{
    setSalesUnix(event){
      this.salesData.SalesDate = event.getTime();
    },

    setDeliveryUnix(event){
      this.salesData.DeliveryDate = event.getTime();
    },

    setContact(contact){
      this.salesData.Contact = contact;
    },

    calculateMissingAmount(event){
      this.salesData.CommissionValue = Number(event).toFixed(2);
      this.currentPaymentEntry.MissingAmount = Number(event).toFixed(2) - Number(this.currentPaymentEntry.AmountPaid).toFixed(2);
    },

    setPayment(amountPaid){
      this.currentPaymentEntry.AmountPaid = Number(amountPaid).toFixed(2);
      this.currentPaymentEntry.MissingAmount = Number(this.salesData.CommissionValue).toFixed(2) - Number(amountPaid).toFixed(2);
    },

    setPaymentDateUnix(event){
      this.currentPaymentEntry.PaymentDateUnix = event.getTime();
    },

    setPaymentDeadlineUnix(event){
      this.currentPaymentEntry.PaymentDeadlineUnix = event.getTime();
    },

    addSale(){
      if(this.salesData.VisibleTo === "Self") this.salesData.Members = [
        {
          Id: this.currentUserData.User,
          Name: this.currentUserData.FriendlyName,
          type:"User"
        }
      ];

      if(this.salesData.VisibleTo === "All") this.salesData.Members = this.project.Members;

      this.v$.$validate();
      if(!this.v$.$error) {
        if (this.salesData.VisibleTo === 'Members') {
          const foundCreator = this.salesData.Members.filter(member => member.Id === this.$store.getters.username)[0];
          if (!foundCreator) this.pushCreatorIntoMembers();
        }

        if(this.currentPaymentEntry.AmountPaid){
          this.currentPaymentEntry.MissingAmount = Number(this.currentPaymentEntry.MissingAmount).toFixed(2);
          this.salesData.Payments.push(this.currentPaymentEntry);
        }

        this.salesData.OfferId = this.selectedOffer.Id;
        this.salesData.OfferName = this.selectedOffer.Name;

        if(this.salesData.Contact && this.salesData.Contact.Id){
          this.salesData.ContactId = this.salesData.Contact.Id;
        }
        this.salesData.ResponsibleId = this.salesData.Responsible.Id;

        this.$toast.add({severity: 'success', summary: this.$t('confirmed'), detail: this.$t('swal.saleCreated'), life: 3000});
        
        this.salesData.CommissionValue =  Number(this.salesData.CommissionValue).toFixed(2);
        this.salesData.OrderValue =  Number(this.salesData.OrderValue).toFixed(2);
        this.salesData.OfferValue =  Number(this.salesData.OfferValue).toFixed(2);
        this.salesData.Discount =  Number(this.salesData.Discount).toFixed(2);

        this.$store.dispatch('addSale',this.salesData).then(() => {
          this.$emit('add-sale',this.salesData);
        });
      }else{
        this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('errorKeysNeeded') + this.getErrors(), life: 5000 });
      }
    },

    getErrors(){
      let errorList = "";
      for(let error of this.v$.$errors){
        if(errorList !== ""){
          errorList = errorList + ", ";
        }
        switch(error.$property){
          case "Title":
            errorList = errorList + ' ' + this.$t('title');
            break;
          case "Responsible":
            errorList = errorList + ' ' + this.$t('responsible');
            break;
          case "CommissionNumber":
            errorList = errorList + ' ' + this.$t('commissionNumber')
            break;
          case "OfferValue":
            errorList = errorList + ' ' + this.$t('offerValue');
            break;
          case "VisibleTo":
            errorList = errorList + ' ' + this.$t('placeholder.visibleTo');
            break;
          case "Name":
            errorList = errorList + ' ' + this.$t('contact');
            break;
        }
      }

      return errorList;
    },

    getContactsByCompanyId(){
      this.$store.commit('setLoading',true);
      this.$store.dispatch("getRequest","getContactsByCompanyId&query=" + this.project.Company.Id).then(resp => {
        this.$store.commit('setLoading',false);
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.contactOptions = body.map(contact => ({ Name: contact.ContactName,
            Id:contact.ContactId,
            Email:contact.ContactEmail,
            Phone:contact.OptionalParams ? contact.OptionalParams.Mobile ? contact.OptionalParams.Mobile : "" : "",
            Landline:contact.OptionalParams ? contact.OptionalParams.Landline ? contact.OptionalParams.Landline : "" : ""}));
        }
      });
    },

    
    getSupplierOptions(){
      this.$store.dispatch("getRequest","getTagsByCompanyId&query=" + this.project.Company.Id).then(resp => {
        const body = JSON.parse(resp.body);
        const foundSupplierTag = body.find((tag) => tag.labelName === 'Lieferwerkzuordnung');
        if(foundSupplierTag) this.supplierOptions = foundSupplierTag.selectedValue;
      });
    },
  },

  mounted(){
    this.getSupplierOptions();
  }
}
</script>

<style scoped>

</style>