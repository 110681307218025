import { createStore } from 'vuex'

import leadsStore from "@/store/leads/leads";
import projectsStore from "@/store/projects/projects";
import tasksStore from "@/store/tasks/tasks";
import offersStore from "@/store/offers/offers";
import salesStore from "@/store/sales/sales";
import requestModule from "@/store/requests/requests";
import {Auth} from "aws-amplify";

export default createStore({
  state: {
    username: null,
    shortUsername:null,
    userType:"",
    jwtToken: null,
    domainName:null,
    equipments:[],
    responsibles:[],
    projectPhases:[],
    notifications:[],
    customLists:[],
    tagContainers:[],
    allTagContainers:[],
    allCustomLists:[],
    companyDashboard:null,
    userDashboard:null,
    companyQueryData:[],
    companyLastEvaluatedKey:null,
    contactQueryData:[],
    contactLastEvaluatedKey:null,
    loading:true,
    shouldReloadDashboard:false,
  },

  getters:{
    username: (state) => state.username,
    shortUsername: (state) => state.shortUsername,
    userType: (state) => state.userType,
    jwtToken: (state) => state.jwtToken,
    domainName: (state) => state.domainName,
    equipments: (state) => state.equipments,
    responsibles: (state) => state.responsibles,
    projectPhases: (state) => state.projectPhases,
    notifications: (state) => state.notifications,
    customLists: (state) => state.customLists,
    tagContainers: (state) => state.tagContainers,
    allTagContainers: (state) => state.allTagContainers,
    allCustomLists: (state) => state.allCustomLists,
    companyDashboard: (state) => state.companyDashboard,
    userDashboard: (state) => state.userDashboard,
    companyQueryData: (state) => state.companyQueryData,
    companyLastEvaluatedKey: (state) => state.companyLastEvaluatedKey,
    contactQueryData: (state) => state.contactQueryData,
    contactLastEvaluatedKey: (state) => state.contactLastEvaluatedKey,
    loading: (state) => state.loading,
    shouldReloadDashboard: (state) => state.shouldReloadDashboard,
  },

  actions: {
    getCredentials(context){
      return new Promise((resolve,reject) => {

        // Looking for the current session and user info
        Auth.currentSession().then(session => {
          Auth.currentUserInfo().then(userInfo => {

            // make sure we have a valid user info object.
            if (userInfo && Object.keys(userInfo).length !== 0) {

              // setting localStorage with userInfo and token.
              localStorage.setItem("Salestoken",session.getIdToken().getJwtToken());
              localStorage.setItem("Salesusername",userInfo.username);
              localStorage.setItem("SalesShortUsername",userInfo.username.substring(5,userInfo.username.length));
              localStorage.setItem("SalesDomainName",userInfo.attributes.website);

              // giving credentials to the mutation setCredentials
              context.commit('setCredentials',{
                "jwtToken": session.getIdToken().getJwtToken(),
                "username": userInfo.username,
                "shortUsername":userInfo.username.substring(5,userInfo.username.length),
                "domainName": userInfo.attributes.website
              });
              resolve();
            } else{
              reject();
            }
          });
        });
      })
    },

    getCredentialsFromLocalStorage(context){
      // getting the localStorage items
      const token = localStorage.getItem("Salestoken");
      const username = localStorage.getItem("Salesusername");
      const shortUsername = localStorage.getItem("SalesShortUsername");
      const domainName = localStorage.getItem("SalesDomainName");

      if(token && username){
        context.commit('setCredentials', {
          "jwtToken": token,
          "username": username,
          "shortUsername":shortUsername,
          "domainName":domainName
        });
      }
    },

    signout(context){
      // we set all credentials in the store back to null
      context.commit('setCredentials',{
        "username": null,
        "jwtToken": null,
        "shortUsername":null,
        "domainName": null
      });

      context.commit('emptyStore');

      // removing all items in localStorage
      localStorage.removeItem("Salestoken");
      localStorage.removeItem("Salesusername");
      localStorage.removeItem("SalesShortUsername");
      localStorage.removeItem("SalesDomainName");
    },

    /**
     * Adds the correspondence to the corresponding type (i.e. company, user, contact)
     * and sends the new data to the server and sets the corresponding dashboard to the new data,
     * if we are inside the correct dashboard.
     * (Not needed if not inside a dashboard since we would get the new data when opening the dashboard anyway)
     * @param context
     * @param data
     */
    addCorrespondence(context,data){
      context.commit('setLoading',true);
      if(data.Company){
        return new Promise((resolve) => {
          context.dispatch("postRequest",{
            "param":"updateCompanyCorrespondence",
            "payload":{"Correspondence":data.Company.Correspondence, "Id":data.Company.Id},
          }).then(()=> {
            const isCompanyDashboard = context.state.companyDashboard && context.state.companyDashboard.Id === data.Company.Id;
            if(isCompanyDashboard){
              context.commit('setCompanyDashboard',data.Company);
            }
            context.commit('setLoading',false);
            resolve();
          });
        });
      }else if(data.Person){
        return new Promise((resolve) => {
          context.dispatch("postRequest",{
            "param":"updateUserCorrespondence",
            "payload":{"Correspondence":data.Person.Correspondence, "Id":data.Person.User},
          }).then(()=> {
            const isUserDashboard = context.state.userDashboard && context.state.userDashboard.User === data.Person.User;
            if(isUserDashboard){
              context.commit('setUserDashboardCorrespondence',data.Person);
            }
            context.commit('setLoading',false);
            resolve();
          });
        });
      }else if(data.Contact){
        return new Promise((resolve) => {
          context.dispatch("postRequest",{
            "param":"updateContactCorrespondence",
            "payload":{"Correspondence":data.Contact.Correspondence, "Id":data.Contact.ContactId},
          }).then(()=> {
            const isUserDashboard = context.state.userDashboard && context.state.userDashboard.ContactId === data.Contact.ContactId;
            if(isUserDashboard){
              context.commit('setUserDashboard',data.Contact);
            }
            context.commit('setLoading',false);
            resolve();
          });
        });
      }
    },

    deleteCorrespondence(context,data){
      context.commit('setLoading',true);
      if(data.Company){
        return new Promise((resolve) => {
          context.dispatch("postRequest",{
            "param":"updateCompanyCorrespondence",
            "payload":{"Correspondence":data.Company.Correspondence, "Id":data.Company.Id},
          }).then(()=> {
            context.commit('setCompanyDashboard',data.Company);
            context.commit('setLoading',false);
            resolve();
          });
        });
      }else if(data.Person){
        return new Promise((resolve) => {
          context.dispatch("postRequest",{
            "param":"updateUserCorrespondence",
            "payload":{"Correspondence":data.Person.Correspondence, "Id":data.Person.User},
          }).then(()=> {
            context.commit('setUserDashboard',data.Person);
            context.commit('setLoading',false);
            resolve();
          });
        });
      }else if(data.Contact){
        return new Promise((resolve) => {
          context.dispatch("postRequest",{
            "param":"updateContactCorrespondence",
            "payload":{"Correspondence":data.Contact.Correspondence, "Id":data.Contact.ContactId},
          }).then(()=> {
            context.commit('setUserDashboard', data.Contact);
            context.commit('setLoading',false);
            resolve();
          });
        });
      }
    },

    deleteS3Object(context, filePath){
      context.commit('setLoading',true);
      return new Promise((resolve) => {
        context.dispatch("postRequest", {
          "param":"deleteS3Object",
          "payload":filePath,
        }).then(() => {
          context.commit('setLoading',false);
          resolve();
        });
      });
    },

    addContact(context,contact){
      context.commit('setLoading',true);
      return new Promise((resolve) => {
        context.dispatch("postRequest", {
          "param":'createContact',
          "payload":contact,
        }).then(() => {
          context.commit('setLoading',false);
          resolve();
        });
      });
    },

    deleteNotification(context, notification){
      context.commit('setLoading',true);
      return new Promise((resolve) => {
        context.dispatch('postRequest',{
          'param':'blockTaskNotifications',
          'payload':notification.id,
        }).then(() => {
          context.commit('deleteNotification',notification);
          context.commit('setLoading',false);
          resolve();
        });
      }
    )},
  },

  mutations: {
    setCredentials(state, payload){
      state.jwtToken = payload.jwtToken;
      state.username = payload.username;
      state.shortUsername = payload.shortUsername;
      state.domainName = payload.domainName;
    },

    setEquipments(state,equipments){
      state.equipments = equipments.sort((a, b) => {
        if (a['EquipmentName'] < b['EquipmentName']) return -1;
        if (a['EquipmentName'] > b['EquipmentName']) return 1;
      });
    },

    setResponsibles(state,responsibles){
      state.responsibles = responsibles.sort((a, b) => {
        if (a['FriendlyName'] < b['FriendlyName']) return -1;
        if (a['FriendlyName'] > b['FriendlyName']) return 1;
      });
      const foundUser = responsibles.filter(user => user.User === state.username)[0];
      state.userType = foundUser.UserType;
    },

    setProjectPhases(state,projectPhases){
      state.projectPhases = projectPhases;
    },

    setAllCustomLists(state,customLists){
      state.allCustomLists = customLists;
    },

    setCustomLists(state,customLists){
      const salesList = [];
      for(let customList of customLists){
        if(customList.App === "sales") salesList.push(customList);
      }
      state.customLists = salesList;
    },

    setAllTagContainers(state,tagContainers){
      state.allTagContainers = tagContainers;
    },

    setTagContainers(state,tagContainers){
      const salesTagContainers = [];
      for(let tagContainer of tagContainers){
        if(tagContainer.App === "sales") salesTagContainers.push(tagContainer);
      }
      state.tagContainers = salesTagContainers;
    },

    setNotifications(state, notifications){
      state.notifications = notifications;
    },

    deleteNotification(state, notification){
      state.notifications = state.notifications.filter(foundNotification => foundNotification.id !== notification.id);
    },

    setCompanyDashboard(state, companyData){
      state.companyDashboard = companyData;
    },

    setUserDashboard(state, userData){
      state.userDashboard = userData;
    },

    setUserDashboardCorrespondence(state, userData){
      state.userDashboard.Correspondence = userData.Correspondence;
    },

    setCompanyQueryData(state, companies){
      state.companyQueryData = [...state.companyQueryData, ...companies];
    },

    setCompanyLastEvaluatedKey(state, lastEvaluatedKey){
      state.companyLastEvaluatedKey = lastEvaluatedKey;
    },

    setContactQueryData(state, contacts){
      state.contactQueryData = [...state.contactQueryData, ...contacts];
    },

    setContactLastEvaluatedKey(state, lastEvaluatedKey){
      state.contactLastEvaluatedKey = lastEvaluatedKey;
    },

    setLoading(state, isLoading){
      state.loading = isLoading;
    },

    setShouldReloadDashboard(state,trueOrFalse){
      state.shouldReloadDashboard = trueOrFalse;
    },

    emptyStore(state){
      state.projectsStore.projects = [];
      state.projectsStore.favorisedProjects = [];
      state.projectsStore.closedProjects = [];
      state.projectsStore.openProjectsLastEvaluatedKey = null;
      state.projectsStore.closedProjectsLastEvaluatedKey = null;
      state.leadsStore.leads = [];
      state.leadsStore.closedLeads = [];
      state.leadsStore.openLeadsLastEvaluatedKey = null;
      state.leadsStore.closedLeadsLastEvaluatedKey = null;
      state.offersStore.offers = [];
      state.tasksStore.tasks = [];
      state.salesStore.sales = [];
      state.salesStore.salesLastEvaluatedKey = null;
      state.customLists = [];
      state.tagContainers = [];
      state.notifications = [];
      state.responsibles = [];
      state.equipments = [];
      state.favorisedProjects = [];
      state.allCustomLists = [];
      state.allTagContainers = [];
      state.userType = "";
      state.companyQueryData = [];
      state.companyLastEvaluatedKey = null;
      state.contactQueryData = [];
      state.contactLastEvaluatedKey = null;
    }
  },

  modules: {
    leadsStore,
    projectsStore,
    tasksStore,
    offersStore,
    salesStore,
    requestModule,
  }
})
