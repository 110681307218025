<template>
  <div style="min-height: 600px">
    <div class="col-lg-12 control-section">
      <div class="content-wrapper">
        <DataTable
          ref="dataTable"
          :value="gridDataSource"
          paginator
          :rows="20"
          :rowsPerPageOptions="[10, 20, 50]"
          :scrollable="true"
          dataKey="Id"
          removableSort
          selectionMode="single"
          v-model:filters="filter"
          filterDisplay="menu"
          :globalFilterFields="globalFilter"
          @rowSelect="goToProjectDashboard">
          <template #header>
            <div class="flex xl:justify-content-end align-items-center">
              <div v-if="lastEvaluatedKey" class="flex align-items-center gap-2">
                <PrimeButton style="height:36px;"
                  class="mr-2"
                  outlined
                  size="small"
                  severity="secondary"
                  @click="getMoreSales">
                  {{$t('loadMoreData')}}
                </PrimeButton>
              </div>

            <PrimeButton style="height:36px;min-width:36px"
              label="Excel"
              icon="pi pi-file-excel"
              class="mr-2"
              outlined
              severity="secondary"
              @click="exportToExcel" />
              <span
                class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filter['global'].value"
                  :placeholder="this.$t('placeholder.search')"/>
              </span>
            </div>
          </template>
          <Column v-for="(col,index) of columns"
            :key="col.field + '_' + index" :field="col.field"
            :filterField="col.field"
            :columnKey="col.field"
            :showFilterMatchModes="false"
            sortable
            :style="{minWidth: '100px'}"
            style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">
            <template #header>
              <span v-tooltip.top="col.header"
                class="headerLabel">{{ col.header }}</span>
            </template>
            <template #body="{data, field}">
              <span v-tooltip.top="data[field]">{{ data[field] }}</span>
            </template>
            <template v-if="filter" #filter="{ filterModel}">
              <MultiSelect
                class="p-column-filter"
                v-model="filterModel.value"
                :options="getColumnFilterOptions(col.field)"
                placeholder="any"
                filter
                autoFilterFocus>
                <template #option="slotProps">
                  <div class="flex align-items-center gap-2">
                    <span>{{slotProps.option}}</span>
                  </div>
                </template>
              </MultiSelect>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>

import QueryBuilderFunctions from "@/mixins/query-builder-functions/queryBuilderFunctions";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
import PrimeButton from "primevue/button";
import * as XLSX from 'xlsx';
import { FilterMatchMode } from "primevue/api";

export default {
  name: "SalesQueryTable",
  mixins:[QueryBuilderFunctions],
  components:{
    DataTable, Column, MultiSelect, InputText, PrimeButton,
  },
  data(){
    return{
      columns:[
        { field: 'Title', header: this.$t('title') },
        { field: 'CommissionNumber', header: this.$t('commissionNumber') },
        { field: 'CommissionValue', header: this.$t('commissionValue') },
        { field: 'Contact', header: this.$t('contact') },
        { field: 'Responsible', header: this.$t('responsible') },
        { field: 'OrderNumber', header: this.$t('orderNumber') },
        { field: 'OrderValue', header: this.$t('orderValue') },
        { field: 'ReferenceNumber', header: this.$t('referenceNumber') },
        { field: 'SalesDate', header: this.$t('salesDate') },
        { field: 'DeliveryDate', header: this.$t('deliveryDate') },
        { field: 'Equipment', header: this.$t('equipments') },
        { field: 'OfferName', header: this.$t('offer') },
        { field: 'OfferValue', header: this.$t('offerValue') },
        { field: 'Supplier', header: this.$t('supplier') },
        { field: 'CompetitionModel', header: this.$t('competitionModel') },
        { field: 'MachineModel', header: this.$t('machineModel') },
        { field: 'MachineNumber', header: this.$t('machineNumber') },
        { field: 'Discount', header: this.$t('discountPercentage') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title:{value: null, matchMode: FilterMatchMode.IN},
        CommissionNumber:{value: null, matchMode: FilterMatchMode.IN},
        CommissionValue:{value: null, matchMode: FilterMatchMode.IN},
        Contact:{value: null, matchMode: FilterMatchMode.IN},
        Responsible:{value: null, matchMode: FilterMatchMode.IN},
        OrderNumber:{value: null, matchMode: FilterMatchMode.IN},
        OrderValue:{value: null, matchMode: FilterMatchMode.IN},
        ReferenceNumber:{value: null, matchMode: FilterMatchMode.IN},
        SalesDate:{value: null, matchMode: FilterMatchMode.IN},
        DeliveryDate:{value: null, matchMode: FilterMatchMode.IN},
        Equipment:{value: null, matchMode: FilterMatchMode.IN},
        OfferName:{value: null, matchMode: FilterMatchMode.IN},
        OfferValue:{value: null, matchMode: FilterMatchMode.IN},
        Supplier:{value: null, matchMode: FilterMatchMode.IN},
        CompetitionModel:{value: null, matchMode: FilterMatchMode.IN},
        MachineModel:{value: null, matchMode: FilterMatchMode.IN},
        MachineNumber:{value: null, matchMode: FilterMatchMode.IN},
        Discount:{value: null, matchMode: FilterMatchMode.IN},
      },
      globalFilter:['Title', 'CommissionNumber', 'CommissionValue', 'Contact',
       'Responsible', 'OrderNumber', 'OrderValue', 'ReferenceNumber', 'SalesDate', 'DeliveryDate', 'Equipment',
       'OfferName', 'OfferValue', 'Supplier', 'CompetitionModel', 'MachineModel', 'MachineNumber', 'Discount'],
    }
  },
 
  computed:{
    gridDataSource(){
      return this.prepareDataSource(this.$store.getters.sales);
    },

    getColumnFilterOptions() {
      return (field) => {
        // Extract unique values for the given field
        const uniqueValues = new Set(
          this.gridDataSource.map(item => item[field]).filter(value => value != null) // filter out null/undefined
        );
        return Array.from(uniqueValues).map(value => ( value ));
      };
    },

    lastEvaluatedKey(){
      return this.$store.getters.salesLastEvaluatedKey;
    }
  },

  methods:{
    prepareDataSource(sales){
      let src = [];

      for (let sale of sales){
        let obj = {};
        obj["Title"] = sale["Title"];
        obj["CommissionNumber"] = sale["CommissionNumber"];
        obj["CommissionValue"] = sale["CommissionValue"];
        obj["Contact"] = this.getValFromObject(sale.Contact, "Name");
        obj["Responsible"] = this.getValFromObject(sale.Responsible, "Name");
        obj["OrderNumber"] = sale["OrderNumber"];
        obj["OrderValue"] = sale["OrderValue"];
        obj["ReferenceNumber"] = sale["ReferenceNumber"];
        obj["SalesDate"] = new Date(Number(sale.SalesDate)).toLocaleDateString();
        obj["DeliveryDate"] = new Date(Number(sale.DeliveryDate)).toLocaleDateString();
        obj["Equipment"] =  this.getEquipmentNames(sale.Equipment);
        obj['OfferName'] = sale['OfferName'];
        obj["OfferValue"] = sale["OfferValue"];
        obj['Supplier'] = Array.isArray(sale['Supplier']) ? '-' : sale['Supplier'];
        obj['CompetitionModel'] = sale['CompetitionModel'];
        obj['MachineModel'] = sale['MachineModel'];
        obj['MachineNumber'] = sale['MachineNumber'];
        obj['Discount'] = sale['Discount'];

        obj['ProjectId'] = sale['ProjectId'];

        src.push(obj);
      }

      return src;
    },

    getEquipmentNames(equipments){
      const equipmentNames = [];
      for(let equipment of equipments){
        equipmentNames.push(equipment.Name);
      }

      return equipmentNames.toString();
    },
        
    goToProjectDashboard(args){
      const projectId = args.data.ProjectId;
      this.$router.push({
        path:"/projects/dashboard/" + projectId,
        params:projectId
      });
    },

    exportToExcel(){
      const filteredData = this.gridDataSource.map(row => {
        // Create a new object excluding the 'Id' field
        // eslint-disable-next-line no-unused-vars
        const { ProjectId, ...rest } = row;
        return rest;
      });
      // Convert filtered data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(filteredData);

      // Set custom headers
      const headers = this.columns
        .filter(col => col.field !== 'ProjectId')
        .map(col => col.header);
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sales DataTable Export');

      // Export the workbook to an Excel file
      XLSX.writeFile(workbook, 'sales_datatable.xlsx');
    },

    getMoreSales(){
      if(this.lastEvaluatedKey && this.lastEvaluatedKey.SalesDate && this.lastEvaluatedKey.Id){
        const id = Number(this.lastEvaluatedKey["Id"]);
        const salesDate = Number(this.lastEvaluatedKey["SalesDate"]);
        this.$store.dispatch("getRequest","getMoreSales&query=" +  [id, salesDate]).then(resp => {
          if (resp && resp.statusCode === 200) {
            const body = JSON.parse(resp.body);
            this.$toast.add({ severity: 'success', summary: this.$t('done'), detail: this.$t('swal.dataUpToDate'), life: 3000 });
            this.$store.commit('setSales', body.Items);
            this.prepareDataSource(body.Items);

            if (body.LastEvaluatedKey && body.Items.length > 0) {
              this.$store.commit('setSalesLastEvaluatedKey', body.LastEvaluatedKey);
            }else{
              this.$store.commit('setSalesLastEvaluatedKey', null);
            }
          }
        });
      }else{
        this.$toast.add({ severity: 'error', summary: this.$t('Info'), detail: this.$t('swal.allDataReceived'), life: 5000 });
      }
    },
  },
}
</script>

<style scoped>
.headerLabel{
  display: flex;
  justify-content: space-between; /* Space between text and filter button */
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

::v-deep .p-multiselect-label{
  display: flex;
  justify-content: space-between; /* Space between text and filter button */
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

::v-deep .p-datatable .p-datatable-tbody > tr > td {
  padding: 8px 21px !important;
}
</style>