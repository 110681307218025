<template>
  <TabView>
    <TabPanel :header="this.$t('open') + ' (' + leadsData.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'leads'"
                     :style="{'height': windowHeight}"
                     :data-prop="leadsData"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :filter-prop="filter"
                     :show-convert-button="true"
                     @convert-data="convertLead"
                     :filter-options="filterOptions"
                     :show-delete-button="true"
                     @row-deleted="deleteLead"
                     :show-close-button="true"
                     @close-data="closeLead"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     @row-selected="editLead"
                     :has-reminder="true"
                     :resizable-columns="true"
                     :show-toggle-column="true"
                     :show-load-more-data="openLeadsLastEvaluatedKey"
                     @load-more-data="loadMoreLeads"
                     :close-button-tool-tip="$t('swal.closeLeadHeader')"
                     :delete-button-tool-tip="$t('swal.deleteLeadHeader')"/>
    </TabPanel>

    <TabPanel :header="this.$t('myLeads') + ' (' + myLeads.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'myLeads'"
                     :style="{'height': windowHeight}"
                     :data-prop="myLeads"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :filter-prop="filter"
                     :show-convert-button="true"
                     @convert-data="convertLead"
                     :filter-options="filterOptions"
                     :show-delete-button="true"
                     @row-deleted="deleteLead"
                     :show-close-button="true"
                     @close-data="closeLead"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     @row-selected="editLead"
                     :has-reminder="true"
                     :resizable-columns="true"
                     :show-toggle-column="true"
                     :show-load-more-data="openLeadsLastEvaluatedKey"
                     @load-more-data="loadMoreLeads"
                     :close-button-tool-tip="$t('swal.closeLeadHeader')"
                     :delete-button-tool-tip="$t('swal.deleteLeadHeader')"/>
    </TabPanel>

    <TabPanel :header="this.$t('closed') + ' (...)'">
      <div class="w-full" style="min-height:30vh" >
        <SearchableSelectRow :show-table="true"
                             :show-timespan="true"
                             :search-option="'ClosedLeads'"
                             :placeholder="this.$t('placeholder.searchClosedLead')"
                             :reset-filter-value="resetFilterValue"
                             @selection-changed="editLead"
                             @reset-filter-value="resetFilterValue = false" />
      </div>
    </TabPanel>
  </TabView>

  <Popup :modal="isDesktop"
         :visible="editingLead"
         :header="this.$t('editLeads')"
         styling="width:90%"
         :show-save-button="showSaveButton"
         @button-clicked="submitEditLeadPopup"
         @close-popup="editingLead = false"
         :show-menu-button="true"
         :menu-options="editLeadMenuOptions"
         :show-inline-message="true"
         :inline-message="inlineMessage">
    <EditLead ref="editLead"
              :lead-data-prop="selectedLead"
              :disabled="selectedLead.Status === '20'"
              :show-reminder="showReminders"
              @close-reminders="showReminders = false"
              @submit-edit-lead="submitLead"/>
  </Popup>
</template>

<script>

import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import Popup from "@/components/global-components/popup/Popup"
import {FilterMatchMode} from "primevue/api";
import EditLead from "@/components/leads/edit-lead/EditLead";
import SearchableSelectRow from "@/components/global-components/searchable-select-row/SearchableSelectRow";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import arrayHasAllKeys from "@/mixins/array-has-all-keys/array-has-all-keys";
import S3FileManager from "@/mixins/s3-file-manager/s3-file-manager";
import Uuidv4Generator from "@/mixins/uuidv4/Uuidv4Generator";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";
import getStoreData from "@/mixins/get-store-data/getStoreData";

export default {
  name: "LeadsComponent",
  components:{
    EditLead, MaterialTable, Popup, SearchableSelectRow, TabView, TabPanel,
  },
  mixins:[getStoreData, arrayHasAllKeys, S3FileManager, Uuidv4Generator, globalComputedProperties],
  data(){
    return {
      columns: [
        { field: 'CreationNumber', header: this.$t('number') },
        { field: 'Title', header: this.$t('title') },
        { field: 'Description', header: this.$t('description') },
        { field: 'Creator', header: this.$t('creator') },
        { field: 'Responsible.Name', header: this.$t('responsible') },
        { field: 'Created', header: this.$t('createdOn') },
        { field: 'Company.Name', header: this.$t('company') },
        { field: 'Prio', header: "Prio" },
        { field: 'OpenSince', header: this.$t('openSince') },
        { field: 'Location.LocationName', header: this.$t('location') },
        { field: 'Supplier', header: this.$t('supplier') }
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CreationNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Creator: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Responsible.Name': { value: null, matchMode: FilterMatchMode.IN },
        Created: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Company.Name': { value: null, matchMode: FilterMatchMode.IN },
        Prio: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OpenSince: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Location.LocationName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        Supplier: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['CreationNumber', 'Title', 'Description', 'Creator',
        'Responsible.Name', 'Company.Name', 'Prio', 'Supplier', 'Location.LocationName'],
      editingLead: false,
      resetFilterValue: false,
      selectedLead: null,
      //key of material-table that increases on pressing refresh
      tableKey:0,

      showReminders:false,
      editLeadMenuOptions: [],
      showSaveButton:false,
      inlineMessage:"",
      windowHeight: '',
    }
  },
  computed:{
    /**
     * @returns {[]|*}
     */
    leadsData(){
      const leads = [];
      for(let lead of this.$store.getters.leads){
        if(lead.Status === "10") leads.push(lead);
      }

      return leads.sort((a, b) => {
        if (a['Created'] > b['Created']) return -1;
        if (a['Created'] < b['Created']) return 1;
      });
    },

    myLeads(){
      const myLeads = [];
      for(let lead of this.leadsData){
        const foundMember = lead.Members.filter(member => member.Id === this.$store.getters.username)[0];
        if(foundMember) myLeads.push(lead);
        if(!foundMember){
          if(lead.Responsible.Id === this.$store.getters.username) myLeads.push(lead);
        }
      }
      return myLeads
    },

    projectTagContainers(){
      return this.$store.getters.tagContainers.filter(tagContainer => tagContainer.Coverage === "Projects");
    },

    openLeadsLastEvaluatedKey(){
      return this.$store.getters.openLeadsLastEvaluatedKey;
    }
  },
  methods:{
    /**
     * Opens the popup for edit-lead and sets the selectedLead.
     * @param leadData
     */
    editLead(leadData){
      this.createEditLeadMenuOptions(leadData);
      this.showSaveButton = leadData.Status === '10';
      this.inlineMessage = this.$t('openSince') + ' ' + this.getDueDate(leadData.Created) + ' ' +this.$t('days');
      this.editingLead = true;
      this.selectedLead = leadData;
    },

    createEditLeadMenuOptions(leadData){
      const optionsMenu = []
      if(leadData.Status === '10'){
        if(this.$store.getters.userType.toLowerCase() === "admin"){
          optionsMenu.push(
              { label: this.$t('swal.deleteLeadHeader'), icon: 'pi pi-fw pi-trash',
                command: () => { this.deleteLead(leadData);}
              },
              { separator : true},
          )
        }
        if(leadData.Company.Id.length > 0 || leadData.Company.Id === '-'){
          optionsMenu.push(
              { label: this.$t('swal.convertLeadHeader'), icon: 'pi pi-fw pi-sign-in',
                command: () => {this.convertLead(leadData);}
              },
              { separator : true},
          )
        }
        optionsMenu.push(
            { label: this.$t('swal.closeLeadHeader'), icon: 'pi pi-fw pi-check',
              command: () => {this.closeLead(leadData);}
            },
            { separator : true},
            { label: this.$t('openReminder'), icon: 'pi pi-fw pi-clock',
              command: () => {this.openReminder();}
            }
        )
      }else{
        optionsMenu.push(
            { label: this.$t('swal.reopenLeadHeader'), icon: 'pi pi-fw pi-check',
              command: () => {this.reopenLead(leadData);}
            },
        )
      }


      this.editLeadMenuOptions = optionsMenu;
    },

    submitEditLeadPopup(){
      this.$refs.editLead.checkIfFilesNotUploaded();
    },

    /**
     * Dispatches editLead to store and closes the popup while emptying the selectedLead as well.
     * @param editedLeadData
     */
    submitLead(editedLeadData){
      editedLeadData.LastEdited = new Date().getTime();
      editedLeadData.Phase = {Number:"10",Name:'Projektanfrage'};
      this.$store.dispatch('editLead',editedLeadData).then(() => {
        this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('swal.leadEdited'), life: 3000 });
        this.editingLead = false;
        this.selectedLead = {};
      });
    },

    reopenLead(selectedLead){
      this.$confirm.require({
        header: this.$t('swal.reopenLeadHeader'),
        message: this.$t('swal.reopenLeadText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          selectedLead.Status = "10";
          this.$store.dispatch('reopenLead',selectedLead).then(() => {
            this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.leadReopened'), life: 3000 });
            this.resetFilterValue = true;
            this.editingLead = false;
            this.selectedLead = {};
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    /**
     * First we need to see if this lead has any files and set the files variable.
     * Afterwards we show the confirmation dialog. When we accept the convert,
     * we need to nextTick function, otherwise the second dialog will not show.
     * Then we check if the leadData has set any Tags. If it has, we check if any
     * project container contains all LeadTags that are used. If it does,
     * we open a second confirmation dialog for the takeover of tagContainer and tags.
     * @param leadData
     */
    convertLead(leadData){
      this.$confirm.require({
        header: this.$t('swal.convertLeadHeader'),
        message: this.$t('swal.convertLeadText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$nextTick(() => {
            if(Object.keys(leadData.LeadTags).length > 0){
              const foundContainerWithAllKeys = this.projectTagContainers.filter(container => this.arrayHasAllKeys(container.Tags,leadData.LeadTags))[0];
              if(foundContainerWithAllKeys){
                this.takeOverLeadTagsDialog(leadData,foundContainerWithAllKeys);
              }else{
                this.submitConvertLead(leadData);
              }
            }else{
              this.submitConvertLead(leadData);
            }
          })
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });

    },

    /**
     * After confirming the convertLead dialog, we ask if we want to
     * keep our classification & tags as our project class. & tags.
     * By accepting we set the project class. & tags to the lead ones.
     * If we decline we will just convert our lead.
     * @param leadData
     * @param container
     */
    takeOverLeadTagsDialog(leadData,container){
      this.$confirm.require({
        header: this.$t('swal.takeOverLeadTagsHeader'),
        message: this.$t('swal.takeOverLeadTagsText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          leadData.ProjectTagContainer = container;
          leadData.ProjectTags = leadData.LeadTags;
          this.submitConvertLead(leadData);
        },
        reject: () => {
          this.submitConvertLead(leadData);
        }
      });
    },

    /**
     * We show a short confirmation popup in the top-right corner and
     * remove the lead from the store and push them into the open project array.
     * We will route to the now converted project and open the dashboard.
     * @param leadData
     */
    submitConvertLead(leadData){
      leadData.LeadConversionDate = new Date().getTime();
      leadData.Phase = {Number:10,Name:'Projektanfrage'};
      leadData.Result = '40';
      leadData.Type = "Project";
      this.$store.dispatch('convertLead',leadData).then(() => {
        this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.leadConverted'), life: 3000 });
        this.closeEditLead();
        this.$router.push({
          path:"/projects/dashboard/" + leadData.Id,
          params:leadData.Id
        });
      });
    },

    /**
     * Shows an alert that asks for confirmation to close the lead.
     * If we accept we show a short confirmation popup in the top-right corner and
     * remove the lead from the store and push them into the closed lead array.
     * If we decline we show a short reject popup in the top-right corner.
     * @param leadData
     */
    closeLead(leadData){
      this.$confirm.require({
        header: this.$t('swal.closeLeadHeader'),
        message: this.$t('swal.closeLeadText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          leadData.Status = "20";
          leadData.Closed = new Date().getTime();
          this.$store.dispatch('closeLead',leadData).then(() => {
            this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.leadClosed'), life: 3000 });
            this.closeEditLead();
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    /**
     * Shows an alert that asks for confirmation to delete the lead.
     * If we accept we show a short confirmation popup in the top-right corner and
     * remove the lead from the store.
     * If we decline we show a short reject popup in the top-right corner.
     */
    deleteLead(leadData){
      this.$confirm.require({
        header: this.$t('swal.deleteLeadHeader'),
        message: this.$t('swal.deleteLeadText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$store.dispatch('deleteLead',leadData).then(() => {
            this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.leadDeleted'), life: 3000 });
            this.deleteFolder('sales/leadsAndProjects/' + leadData.Id + '/');
            this.closeEditLead();
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    /**
     * Fires when clicking the x on the popup and closes the edit-lead popup and emptying selectedLead.
     */
    closeEditLead(){
      this.editingLead = false;
      this.selectedLead = {};
    },

    openReminder(){
      this.showReminders = true;
    },

    /**
     * Calculates the due date of the lead.
     * @param unix
     * @returns {number}
     */
    getDueDate(unix){
      const nowUnix = new Date().getTime() / 1000;
      const timeStamp = unix / 1000;

      return (Math.round((nowUnix - timeStamp) / 60 / 60 / 24))
    },

    loadMoreLeads(){
      this.getMoreLeads();
    }
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.66 + 'px';
  }
}
</script>

<style scoped>

:deep(.p-tabview-panels){
  padding-left:0 !important;
  padding-right:0 !important;
}

@media screen and (max-width: 820px){
  #header-controls-container{
    display:block!important;
  }
}


</style>